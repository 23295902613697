import Utils from "../../utils";

export function Count(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.count;
    Utils.api.getApiCall(
      endPoint,
      ``,
      payload,
      function (response: any) {
        if (response.status) {
          let data: any = response.data.data;
          // dispatch({
          //   type: Utils.actionName.SELECT_CATEGORY,
          //   payload: { data },
          // });
          // console.log(data)
          callback(data);
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
