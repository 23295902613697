import React, { useState, memo } from "react";
import { NavLink } from "react-router-dom";
//import { useTranslation } from "react-i18next";
import { LogoutConfirmationModal } from "../../component/confirmation-modal/confirmationmodal";


interface Props {
  changeLanguage?: any;
}
export default memo(function Onboardingheader(props: Props) {
  const [logoutConfirmation, setLogoutConfirmation] = useState(false),
    //{ t, i18n } = useTranslation(),
    userName = localStorage.getItem("username"),
    recruiterPic = localStorage.getItem("recruiterPic"),
    userid = localStorage.getItem("userId");
  localStorage.setItem("languageSelected", 'en');


  function handleLogout() { localStorage.clear(); }
  function checkFunctionality() {
    var protocol = 'https';
    var userid = localStorage.getItem("userId");

    var page = window.location;
    if (page.href.includes('https')) { protocol = 'https' }
    else if (page.href.includes('http')) { protocol = 'http'; }
    console.log(page)
    if (page.href.includes('my-account-wallet')) { window.location.href = "/buy-select-ammount" }
    else if (page.href.includes('home') || page.href == protocol + "://" + page.host + "/") { window.location.href = userid == "" ? '/signin' : "/buy-select-ammount" }
    else if (page.href.includes('learnmore')) { window.location.href = userid == "" ? '/signin' : "/buy-select-ammount" }
    //window.location.href='/signin' userid = localStorage.getItem("userId");
  }
  return (
  <>
 <header className="header header-sticky">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="topbar">
              <div className="d-block d-md-flex align-items-center text-center">
                <div className="me-0 me-sm-3 mb-3 mb-md-0 d-block d-sm-inline-block">
                  <a href="#"><i className="far fa-envelope me-2"></i>helpcenter@medileaf.com</a>
                </div>
                <div className="me-auto mb-3 mb-md-0 d-block d-sm-inline-block">
                  <a href="tel:1-800-555-1234"><i className="fa fa-phone me-2 fa fa-flip-horizontal"></i>051-2318538</a>
                </div>
                {/* <div className="login mx-3 mb-3 mb-sm-0 d-block d-sm-inline-block">
                  <a href="login.html">Login</a>
                  <span className="mx-2 text-white">|</span>
                  <a href="register.html">Register</a>
                </div> */}
                <div className="social d-block d-sm-inline-block">
                  <ul className="list-unstyled">
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin"></i></a></li>
                    <li><a href="#"><i className="fab fa-pinterest"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-header">
        <div className="container">
          <nav className="navbar navbar-light bg-white navbar-static-top navbar-expand-lg">
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"><i className="fas fa-align-left"></i></button>
            <a className="navbar-brand" href="/">
              <img className="img-fluid" src="images/logo-me.png" alt="logo"/>
            </a>
            <div className="navbar-collapse collapse justify-content-center">
              <ul className="nav navbar-nav">
                <li className="nav-item dropdown">
                  <a className="nav-link" href="/" role="button" aria-haspopup="true" aria-expanded="false">Home<i className="far fa-plus-square"></i></a>
                </li>
                <li className="dropdown nav-item">
                  <a className="nav-link" href="/aboutus">About Us<i className="far fa-plus-square"></i></a>
                </li>
                <li className="dropdown nav-item">
                  <a href="/services" className="nav-link">Services<i className="far fa-plus-square"></i></a>
                </li>
                 <li className="dropdown nav-item">
                  <a href="#" className="nav-link" data-bs-toggle="dropdown">Blog<i className="far fa-plus-square"></i></a>
                  
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Shop <i className="far fa-plus-square"></i>
                  </a>
                  
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contactus">
                    Contact Us <i className="far fa-plus-square"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="add-listing d-none d-sm-block">
              <a className="btn btn-outline-primary" href="#"><i className="fa fa-address-book"></i>Get a quote</a>
            </div>
          </nav>
        </div>
      </div>
    </header>
     </>
  );
});
