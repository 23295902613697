import { UserDataModal } from "../../modal";
import Utils from "../../utils";
export function authReducer(
  state: UserDataModal = new UserDataModal(),
  action: any
) {
  switch (action.type) {
    case Utils.actionName.HANDLE_USER_DATA:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
