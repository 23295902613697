import React, { PureComponent, useState } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import { Input } from "../../../component/input/custominput";
import { CustomPasswordInputState } from "../../../component/input/custominput";
import Authlayout from "../../../applayout/authlayout";
import Utils from "../../../utils";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {changePassword,doRegister} from "../auth.action"
import axios from "axios";
// import { useHistory } from "react-router-dom";
import { LogoutConfirmationModal } from "../../../component/confirmation-modal/confirmationmodal";

interface Props {

  history?: any;
  changePassword: Function;
  doRegister: Function;
}

interface State {
  logoutConfirmation?: any;
  showPostJobModal: boolean;
  authToken: string;

  username: string;
  email: string;
  showLoading: boolean;
  password: String;
  newPassword: String;
  passwordConfirm: String;
  passwordColor: string;
  newPasswordColor: string;
  message:string;
  passwordConfirmColor: string;
  showLoginLoading:boolean;
  error: {
    passwordError: string;
    passwordConfirmError: string;
    newPasswordError:string;
  };
}

export default class RegisterScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      logoutConfirmation: false,
      showPostJobModal: false,
      showLoading:false,
      authToken: "",
      username: "",
      email:"",
      password: "",
      newPassword: "",
      passwordConfirm: "",
      message:"",
      showLoginLoading:false,
      error: {
        passwordError: "",
        passwordConfirmError: "",
        newPasswordError:"",
      },
      passwordColor: "white",
      newPasswordColor: "white",
      passwordConfirmColor: "#white",
    };
    // this.categoryMultiselectRef = React.createRef();
  }
  
  

  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key+'Color']:'white',[key+'passwordConfirmError']:'' });
  };

  // login = async () => {
  //   const form = document.getElementById('reg-form')
  //   form.addEventListener('submit', registerUser)

  //   async function registerUser(event: { preventDefault: () => void; }) {
  //     event.preventDefault()
  //     const username = this.state.username;
  //     const password = this.state.password;

  //     const result = await fetch('/api/register', {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({
  //        username,
  //         password
  //       })
  //     }).then((res) => res.json())

  //     if (result.status === 'ok') {
  //       // everythign went fine
  //       alert('Success')
  //     } else {
  //       alert(result.error)
  //     }
  //   }
  // }
  



  login = async () => {
    let {username, password} = this.state;
    let payload = {username, password}
    const result = await fetch('http://192.168.10.41:5231/api/register',{
      method: 'POST',
      headers:{
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        username,
        password
      })
    }).then((res) => res.json())
    if(result.status === 'ok'){
      alert('Success')
    }
    else{
      alert('Error')
    }
  }

  render() {
    return (
      <>
                 {/* <!--=================================
    banner --> */}
    <section className="inner-banner bg-light">
      <div className="container">
        <div className="row align-items-center intro-title">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              <li className="breadcrumb-item"> <i className="fas fa-chevron-right"></i> <a href="javascript:void(0)">Pages</a></li>
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Register</span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    banner -->

    <!--=================================
    login --> */}
    <section className="space-ptb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-9 col-md-10">
            <div className="login-form">
              {/* <!-- Register form --> */}
              <form>
                <h3 className="mb-4 text-center text-primary">Register to your Account</h3>
                <div className="row align-items-center">
                  <div className="mb-3 col-md-12">
                   <input type="text" className="form-control" id="username" onChange={(e:any) => {
                      this.handleFiledUpdate("username", e.currentTarget.value)}} placeholder="Username" />
                  </div>
                  {/* <div className="mb-3 col-md-12">
                    <input type="email" className="form-control" onChange={(e:any) => {
                      this.handleFiledUpdate("email", e.currentTarget.value)}} id="email" placeholder="Email"/>
                  </div> */}
                  <div className="mb-3 col-md-12">
                    <input type="password" className="form-control"
                    onChange={(e:any) => {
                      this.handleFiledUpdate("password", e.currentTarget.value)}}
                       id="password" placeholder="Password"/>
                  </div>
                  {/* <div className="mb-3 col-md-12">
                    <input type="password" className="form-control" onChange={(e:any) => {
                      this.handleFiledUpdate("passwordConfirm", e.currentTarget.value)}} id="passwordConfirm" placeholder="Confirm Password"/>
                  </div> */}
                  <div className="col-sm-12 mb-0">
                    <button type="button" className="btn btn-outline-primary my-3 my-sm-0" onClick={this.login}>Sign up</button>
                    <span className="ms-3">Already Registered User? <a href="#">Click here to login</a></span>
                  </div>
                </div>
              </form>
              {/* <!-- Register form -->

              <!-- login-social START --> */}
              <div className="login-social-media">
                <div className="mb-4 d-block text-center"><b className="bg-white pl-2 pr-2 d-block">Login or Sign in with</b></div>
                <form className="row">
                  <div className="col-sm-6">
                    <a className="btn facebook-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-facebook-f"></i>Login with Facebook</span></a>
                  </div>
                  <div className="col-sm-6">
                    <a className="btn twitter-bg social-bg-hover d-block mb-3" href="#"><span><i className="fab fa-twitter"></i>Login with Twitter</span></a>
                  </div>
                  <div className="col-sm-6">
                    <a className="btn instagram-bg social-bg-hover d-block mb-3 mb-sm-0" href="#"><span><i className="fab fa-instagram"></i>Login with Instagram</span></a>
                  </div>
                  <div className="col-sm-6">
                    <a className="btn linkedin-bg social-bg-hover d-block" href="#"><span><i className="fab fa-linkedin-in"></i>Login with Linkedin</span></a>
                  </div>
                </form>
              </div>
              {/* <!-- login-social END --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Contact-info --> */}
      </>
    );
  }
}
function mapStateToProps(state: any) { return {}; }

function mapDispatchToProps(dispatch: Function) {
  return {
    changePassword: (payload: any, token: string, callback: Function) =>
    dispatch(changePassword(payload, token, callback)),
    doRegister: (payload: any, token: string, callback: Function) =>
    dispatch(doRegister(payload, callback)),
  };
}

export const RegisterScreenModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(RegisterScreen)
);
//Authlayout()
export const Register = RegisterScreenModule;
