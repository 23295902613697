import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";

import AuthLayout from "../../../applayout/authlayout";
import AppLayout from "../../../applayout/applayout";
import { doLogin } from "..";
import { withTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  history?: any;
  loginResponce?: any;
  doLogin:Function;
}
interface State {
  email: string;
  password: string;
  loginError: boolean;
  passwordIcon: boolean;
  showLoginLoading: boolean;
  emailColor: string;
  passwordColor: string;
  emailErrorShow:string;
  passwordErrorShow:string; 
  callResponseError:string;
  error: {
    emailError: string;
    passwordError: string;
  };
}
export default class SigninScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      emailErrorShow:"",
      passwordErrorShow:"",
      loginError: false,
      emailColor: "white",
      passwordColor: "white",
      callResponseError:'',
      passwordIcon: true,
      showLoginLoading: false,
      error: {
        emailError: "",
        passwordError: "",
      },
    };
  }
  componentDidMount() {
 
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key+'Color']:'white',[key+'ErrorShow']:''});
  };

  handleUserState = async (role:string) => {
    console.log(' state handled')
    localStorage.setItem("role",role)
    // window.location.href="/signup"

  };

  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => {
    console.log('handled')
    let { email, password, error } = this.state;
    let formError = Utils.constant.validateForm({ email, password, error });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.emailError != "" || this.state.error.passwordError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#ed2939", passwordColor: "#ed2939" })
        this.setState({
          emailErrorShow:this.state.error.emailError,
          passwordErrorShow:this.state.error.passwordError,
        })


      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "white", passwordColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    } else {
      // window.location.href="/home"
      this.setState({ showLoginLoading: true });
      const token = await localStorage.getItem("fcmToken");
      let payload = {
        email,
        password,
        // device_token: token,
        // device_type: "3",
        // user_type: Utils.constant.userType.recruiter,
        // device_id: "device123",
        // fcm_token: token,
      };
      //@ts-ignore
      this.props.doLogin(payload, 
        (response: any) => {
        if (!response.loginerror && response.user_id) {
          this.setState(
            { email: "", password: "", showLoginLoading: false },
            () => {
              console.log(response)
              localStorage.setItem("email",email)
            // this.props.history.push('/home')
            window.localStorage.setItem('user_id',response.user_id)
            window.location.href="/home"
            //  this.connect();
             
        // window.location.reload();
            }
          );
        } else {console.log(response)
          this.setState({
            callResponseError:'Invalid Username Or Password'
          })
          //@ts-ignore
        document.getElementById('errorMessage').style.display='block'
        setInterval(function(){ 
          //@ts-ignore
          document.getElementById('errorMessage').style.display='none' }, 5000);
          this.setState({
            showLoginLoading: false,
          });
        }
      });
    }
  };

  render() {
    return (
    <>
    <>
      {/* <div className="container fixed-height">
        <div className="row">
			    <div className="col-md-5 mx-auto">
			      <div id="first">
				      <div className="myform form ">
					      <div className="logo mb-3">
						      <div className="col-md-12 text-center">
							      <h1 className="log">Login</h1>
						      </div>
				        </div>
                   
                <form method="post">
                  <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email address</label>
                      <div className="field-set">
                        <div className="alert alert-danger"id='errorMessage' style={{display:'none'}} role="alert">
                          {this.state.callResponseError}
                        </div>
                        <div className='errorr'>{this.state.emailErrorShow}</div>
                      </div>  
                              <input 
                                style={{background:this.state.emailColor}}
                                value={this.state.email}
                                onChange={(e: any) => {
                                this.handleFiledUpdate("email", e.currentTarget.value);
                                }}
                                type="email" name="email"  className="form-control" id="email" aria-describedby="emailHelp" placeholder="Enter email"/>
                  </div>
                           <div className="form-group">                             
                              <div className='errorr'>{this.state.passwordErrorShow}</div>
                                <label htmlFor="exampleInputEmail1">Password</label>
                                  <input
                                    style={{background:this.state.passwordColor}}
                                    value={this.state.password}
                                    onChange={(e: any) => {
                                    this.handleFiledUpdate("password", e.currentTarget.value);
                                    }}
                                    onKeyDown={this.handleKeyDown}
                                    type="password" name="password" id="password"  className="form-control" aria-describedby="emailHelp" placeholder="Enter Password"/>    
                           </div>
                           <div className="col-md-12 text-center ">
                              <button onClick={this.handleSubmit} type="button" className=" btn btn-block mybtn btn-primary tx-tfm">Login</button>
                           </div>
                           <div className="clearfix"></div>
                          
                         

                           <div className="row">
                              <div className="col-md-6 text-center ">
                              <button onClick={()=>{this.handleUserState("Seller")}} type="button" className=" btn btn-block mybtn btn-primary tx-tfm">Join as Seller </button>
                              </div>
                              <div className="col-md-6 text-center ">
                                  <button onClick={()=>{this.handleUserState("Buyer")}} type="button" className=" btn btn-block mybtn btn-primary tx-tfm">Join as Buyer </button>
                              </div>
                           </div>

                </form>                 
				      </div>
			      </div>			  
          </div>
        </div>
      </div> */}
    </>
       
    {/* <div className="modal fade" id="loginModal" tabIndex= {-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
              <button type="button" className="close position-absolute top-0 right-0 z-index-2 mt-3 mr-3" data-dismiss="modal" aria-label="Close">
                  <svg aria-hidden="true" className="mb-0" width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                  </svg>
              </button>

              
              <div className="modal-body">
                  <form className="js-validate">
                      <div id="login">

                          <div className="text-center mb-7">
                              <h3 className="mb-0">Login</h3>
                              <p>Login to manage your account.</p>
                          </div>

                          <div className="js-form-message mb-4">
                              <label className="input-label">Email</label>

                              <div className="field-set">
                                <div className="alert alert-danger"id='errorMessage' style={{display:'none'}} role="alert">
                                    {this.state.callResponseError}
                                </div>
                                  <div className='errorr'>{this.state.emailErrorShow}</div>
                              </div>  

                              <div className="input-group input-group-sm mb-2">
                              <input 
                                style={{background:this.state.emailColor}}
                                value={this.state.email}
                                onChange={(e: any) => {
                                this.handleFiledUpdate("email", e.currentTarget.value);
                                }} type="email" className="form-control" name="email" id="signinEmail" placeholder="Email" aria-label="Email" required/>
                              </div>
                          </div>

                          <div className="js-form-message mb-3">
                              <label className="input-label">Password</label>
                              <div className="input-group input-group-sm mb-2">
                              <input
                                    style={{background:this.state.passwordColor}}
                                    value={this.state.password}
                                    onChange={(e: any) => {
                                    this.handleFiledUpdate("password", e.currentTarget.value);
                                    }}
                                    onKeyDown={this.handleKeyDown} type="password" className="form-control" name="password" id="signinPassword" placeholder="Password" aria-label="Password" required
                                  data-msg="Your password is invalid. Please try again." />
                              </div>
                          </div>


                      
                          <div className="mb-3">
                              <button onClick={this.handleSubmit} type="button" className="btn btn-sm btn-primary btn-block">Sign In</button>
                          </div>

                         
                      </div>

                      
                  </form>
              </div>
              
          </div>
      </div>
    </div> */}
    {/* ############## */}
        {/* <div className="main-wrapper login-body">
          <div className="login-wrapper">
            <div className="container">
              <img className="img-fluid logo-dark mb-2" style={{height:"60px", }} src={require("../../../assets/icon/Logo2.png")} alt="Logo"/>
                <div className="loginbox">
                  <div className="login-right">
                    <div className="login-right-wrap">
                             <h1>Login</h1>
                      <p className="account-subtitle">Access to our dashboard</p>
                      <form method="post">
                        <div className="form-group">
                          <label className="form-control-label">Email Address</label>
                          <div className="field-set">
                                <div className="alert alert-danger"id='errorMessage' style={{display:'none'}} role="alert">
                                    {this.state.callResponseError}
                                </div>
                                  <div className='errorr'>{this.state.emailErrorShow}</div>
                              </div>  
                          <input 
                                style={{background:this.state.emailColor}}
                                value={this.state.email}
                                onChange={(e: any) => {
                                this.handleFiledUpdate("email", e.currentTarget.value);
                                }} type="email" className="form-control" name="email" id="signinEmail" placeholder="Email" aria-label="Email" required/>
                        </div>
                        <div className="form-group">
                          <label className="form-control-label">Password</label>
                          <div className="pass-group">
                          <input
                                    style={{background:this.state.passwordColor}}
                                    value={this.state.password}
                                    onChange={(e: any) => {
                                    this.handleFiledUpdate("password", e.currentTarget.value);
                                    }}
                                    onKeyDown={this.handleKeyDown} type="password" className="form-control" name="password" id="signinPassword" placeholder="Password" aria-label="Password" required
                                  data-msg="Your password is invalid. Please try again." />
                              <span className="fas fa-eye toggle-password"></span>
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="row">
                            <div className="col-6">
                              <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="cb1"/>
                                  <label className="custom-control-label" htmlFor="cb1">Remember me</label>
                              </div>
                            </div>
                            <div className="col-6 text-right">
                              <a className="forgot-link" href="/forgot">Forgot Password ?</a>
                            </div>
                          </div>
                        </div>
                        <button className="btn btn-lg btn-block btn-primary"   onClick={this.handleSubmit} type="button">Login</button>
                                            </form>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div> */}
    {/* ############## */ }

 {/* ############## */ }
 <main className="main">
            <nav className="breadcrumb-nav">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="demo1.html"><i className="d-icon-home"></i></a></li>
                        <li><a href="shop.html">Riode Shop</a></li>
                        <li>My Account</li>
                    </ul>
                </div>
            </nav>
            <div className="page-content mt-6 pb-2 mb-10">
                <div className="container">
                    <div className="login-popup">
                        <div className="form-box">
                            <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                                <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5"
                                    role="tablist">
                                    <li className="nav-item">
                                        <a className="nav-link active border-no lh-1 ls-normal" href="#signin">Login</a>
                                    </li>
                                    
                                </ul>
                                <div className="tab-content">
                                    {/* <div className="tab-pane active" id="signin"> */}
                                        <form action="#">
                                            <div className="form-group mb-3">
                                                <input style={{background:this.state.emailColor}} value={this.state.email}
                                onChange={(e: any) => {
                                this.handleFiledUpdate("email", e.currentTarget.value);
                                }}  type="text" className="form-control" id="singin-email"
                                                    name="singin-email" placeholder="Username or Email Address *"
                                                    required />
                                            </div>
                                            <div className="form-group">
                                                <input style={{background:this.state.passwordColor}}
                                    value={this.state.password}
                                    onChange={(e: any) => {
                                    this.handleFiledUpdate("password", e.currentTarget.value);
                                    }}
                                    onKeyDown={this.handleKeyDown} type="password" className="form-control" id="singin-password"
                                                    name="singin-password" placeholder="Password *" required />
                                            </div>
                                            <div className="form-footer">
                                                <div className="form-checkbox">
                                                    <input type="checkbox" className="custom-checkbox" id="signin-remember"
                                                        name="signin-remember" />
                                                    <label className="form-control-label" htmlFor="signin-remember">Remember
                                                        me</label>
                                                </div>
                                                <a href="#" className="lost-link">Lost your password?</a>
                                            </div>
                                            <button className="btn btn-dark btn-block btn-rounded"
                                               onClick={this.handleSubmit} type="button">Login</button>
                                        </form>
                                        <div className="form-choice text-center">
                                            <label className="ls-m">or Login With</label>
                                            <div className="social-links">
                                                <a href="#"
                                                    className="social-link social-google fab fa-google border-no"></a>
                                                <a href="#"
                                                    className="social-link social-facebook fab fa-facebook-f border-no"></a>
                                                <a href="#"
                                                    className="social-link social-twitter fab fa-twitter border-no"></a>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>
 {/* ############## */ }
    

    </>


    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doLogin: (payload: any, callback: Function) =>
      dispatch(doLogin(payload, callback)),
   };
}

export const SigninModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SigninScreen)
);

export  const Signin = AppLayout(SigninModule);
