import React, { PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import { Input } from "../../../component/input/custominput";
import { CustomPasswordInputState } from "../../../component/input/custominput";
import Authlayout from "../../../applayout/authlayout";
import Utils from "../../../utils";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import {changePassword} from "../auth.action"
import { LogoutConfirmationModal } from "../../../component/confirmation-modal/confirmationmodal";

interface Props {

  history?: any;
  changePassword: Function;
}

interface State {
  logoutConfirmation?: any;
  showPostJobModal: boolean;
  authToken: string;
  showLoading: boolean;
  password: String;
  newPassword: String;
  passwordConfirm: String;
  passwordColor: string;
  newPasswordColor: string;
  message:string;
  passwordConfirmColor: string;
  showLoginLoading:boolean;
  error: {
    passwordError: string;
    passwordConfirmError: string;
    newPasswordError:string;
  };
}

export default class ResetScreen extends PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      logoutConfirmation: false,
      showPostJobModal: false,
      showLoading:false,
      authToken: "",
      password: "",
      newPassword: "",
      passwordConfirm: "",
      message:"",
      showLoginLoading:false,
      error: {
        passwordError: "",
        passwordConfirmError: "",
        newPasswordError:"",
      },
      passwordColor: "white",
      newPasswordColor: "white",
      passwordConfirmColor: "#white",
    };
    // this.categoryMultiselectRef = React.createRef();
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value, [key+'Color']:'white',[key+'passwordConfirmError']:'' });
  };

  handleSubmit = async () => {
    console.log('handled')

    let { password,newPassword,  passwordConfirm, error } = this.state;

    let formError = Utils.constant.validatePasswordForm({ password, newPassword, passwordConfirm, error });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.passwordError != "") {
        console.log(this.state.error)
        this.setState({ passwordColor: "#AF0404" })
      }
      else {
        console.log(this.state.error)
        this.setState({ passwordColor: "white" })
      }


      if (this.state.error.newPasswordError != "") {

        this.setState({ newPasswordColor: "#AF0404" })
      }
      else {
        console.log(this.state.error)
        this.setState({ newPasswordColor: "white" })
      }
      
      if (this.state.error.passwordConfirmError != "") {

        this.setState({ passwordConfirmColor: "#AF0404" })
      }
      else {
        console.log(this.state.error)
        this.setState({ passwordConfirmColor: "white" })
      }

    });
    if (!isValid) {
      return false;
    } 
    else {

      this.setState({ showLoginLoading: true });


      let payload = {
        oldPassword: this.state.password,
        newPassword: this.state.newPassword,
        conPassword: this.state.passwordConfirm,
      };

      let token = await localStorage.getItem("accessToken");

      if (token) {
        this.props.changePassword(payload, token, (response:any) => {
console.log('this is the response we need **********************',response)

          let element = document.getElementById("messagediv");

          if (element) 
          {
            console.log('in element')
            element.classList.add("active");

        
           
              element.style.backgroundColor = "#00baee";
              
              element.style.borderColor = "#00baee";

             this.setState({message:response.message}) ;
            

            setTimeout(function () {
              if (element) 
              {
                element.classList.remove("active");
             
                element.innerHTML = "";
              }
            }, 5000);
          }

        });
      }
    }
     // @ts-ignore
    document.getElementById('resetPassModel').click()
    // window.location.href='/reset-success'
  }

  // onToken = (token: any) => {  console.log(token) }

  // handleLogout() { localStorage.clear(); }

  

  // doReset = () => {
  //     let { newpassword } = this.state;
  //     //const parsed = qs.parse(this.props.location.search);

  //     let payload = {
  //         password: newpassword,
  //         email: this.props.location.state.email,
  //         user_type: Utils.constant.userType.recruiter,
  //         resetToken: this.props.location.state.resetToken,
  //     }
  //     this.props.doResetPassword(payload, () => {
  //         this.setState({
  //             newpassword: "",
  //             confirmpassword: "",
  //             error: {
  //                 newpasswordError: "",
  //                 confirmpasswordError: "",
  //             }
  //         });
  //         Utils.constant.successMessage('Password Changed Successfully');
  //         setTimeout(()=>{
  //             this.props.history.push('/');
  //         },1000)

  //     })
  // }
  render() {
    return (
      <section>


       <div className="container">
          <div className="row">
            <div className="col-lg-1 mb-1"></div>
            <div className="col-lg-10 mb-10 connect-line">
              <div className="row">
                <div className="col-lg-2 mb-2"></div>
                <div className="col-lg-8 mb-8">
                  <div style={{marginTop: "100px",textAlign: "center"}} className="connect">
                    <h1>Reset Password!</h1>
                  </div>
                  <div> </div>
                </div>
                <div className="col-lg-2 mb-2"></div>
              </div>
            </div>
            <div className="col-lg-1 mb-1"></div>
          </div>
        </div>



        <div className="row">
            <div className="col-lg-3 mb-3"></div>
            <div className="col-lg-6 mb-6">

            <form className="form-signin form-account">
              <div className="row">
                <div className="col-lg-1 mb-1"></div>
                <div className="col-lg-3 mb-3">
                  <label>Old Password</label>
                  <div className='errorr' >{this.state.error.passwordError}</div>
                  <input type="password" id="password" style={{ backgroundColor: this.state.passwordColor }} className="form-control" placeholder="Password"  autoFocus
                    onChange={(e: any) => {
                      this.handleFiledUpdate("password", e.currentTarget.value);
                    }}

                  />
                </div>
                <div className="col-lg-3 mb-3">
                  <label>New Password</label>
                  <div className='errorr' >{this.state.error.newPasswordError}</div>
                  <input type="password" id="newPassword" style={{ backgroundColor: this.state.newPasswordColor }} className="form-control" placeholder="New Password"  autoFocus
                    onChange={(e: any) => {
                      this.handleFiledUpdate("newPassword", e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="col-lg-3 mb-3">
                  <label>Confirm Password</label>
                  <div className='errorr' >{this.state.error.passwordConfirmError}</div>
                  <input type="password" id="passwordConfirm" style={{ backgroundColor: this.state.passwordConfirmColor }} className="form-control" placeholder="Confirm Password"  autoFocus
                    onChange={(e: any) => {
                      this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                    }}
                  />
                </div>
                <div className="col-lg-1 mb-1"></div>
              </div>
              <div className="row">
                <div className="col-lg-4 mb-4"></div>
                <div className="col-lg-4 mb-4">
                  {/* <button className="btn btn-lg btn-primary btn-block" onClick={()=>{ this.handleSubmit(); }} type="button">Change Password</button> */}
                  <button style={{ background: "#24baef"}} type="button" className="btn btn-secondary" onClick={()=>{ this.handleSubmit(); }} >Change Password</button>

                </div>
                <div className="col-lg-4 mb-4"></div>
              </div>
            </form>
            
                </div>
              </div>



              {/* <!-- Button trigger modal --> */}
    <input id="resetPassModel" type="hidden" data-toggle="modal" data-target="#exampleModalCenterresetPass"/>
      {/* <!-- Modal --> */}
      <div className="modal fade" id="exampleModalCenterresetPass"  role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true" style={{zIndex: 10000}}>
          <div className="modal-dialog modal-dialog-centered" role="document">
              <div className="modal-content">
              <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLongTitle">Message</h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                  </button>
              </div>
              <div className="modal-body">
              <p id='messagediv'>{this.state.message}</p>
                
                </div>
              <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                  {/* <button type="button" className="btn btn-primary">Save changes</button> */}
              </div>
              </div>
          </div>
      </div>
         
      </section>




    );
  }
}
function mapStateToProps(state: any) { return {}; }

function mapDispatchToProps(dispatch: Function) {
  return {
    changePassword: (payload: any, token: string, callback: Function) =>
    dispatch(changePassword(payload, token, callback)),
  };
}

export const ResetScreenModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ResetScreen)
);
//Authlayout()
export const Reset = ResetScreenModule;
