import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/otherlayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '..'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;

}
interface State {
  name: string;
  nameColor: string;

  email: string;
  message:string;
  messageColor: string;

  subject:string;
  subjectColor: string;

  phone:string;
  phoneColor: string;

  showLoginLoading: boolean;

  formSubmitted:string;
  emailColor: string;
  textArea: string;
  emailResponse: string;
  isWhitePaperRequest:boolean;
  error: {
    emailError: string;
    nameError:string;
    subjectError:string;
    messageError:string;
    phoneError:string;
  };
}

export class ContactUsScreen extends PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      name:"",
      nameColor: "#FFFFFF",

      message:"",
      messageColor: "#FFFFFF",

      email: "",
      subject:"",
      subjectColor: "#FFFFFF",

      phone:"",
      phoneColor: "#FFFFFF",
      emailResponse: "",
      emailColor: "#FFFFFF",
      textArea: "",

      formSubmitted:"0",
      isWhitePaperRequest:false,
      showLoginLoading: false,
      error: {
        emailError: "",
        nameError:"",
        subjectError:"",
        messageError:"",
        phoneError:""
      },
    };
  }

  componentDidMount() {

    console.log(window.location.search);
      
    if(window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper"))
    {
      this.setState({isWhitePaperRequest:true});
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => 
  {
    console.log('handled')

    let { email, message, name, subject, phone, error} = this.state;

    let formError = Utils.constant.validateForm({ email, message, name,  subject, phone, error, });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => 
    {
      // Email
      if (this.state.error.emailError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "#121212" })
      }
      // Name 
      if (this.state.error.nameError != ""){
        console.log(this.state.error)
        this.setState({nameColor: "#d65454"})
      }
      else {
        console.log(this.state.error)
        this.setState({nameColor:"#121212"})
      }
      // Message 
      if (this.state.error.messageError != ""){
        console.log(this.state.error)
        this.setState({messageColor: "#d65454"})
      }
      else {
        console.log(this.state.error)
        this.setState({messageColor:"#121212"})
      }
      // Subject 
      if (this.state.error.subjectError != ""){
        console.log(this.state.error)
        this.setState({subjectColor: "#d65454"})
      }
      else {
        console.log(this.state.error)
        this.setState({subjectColor:"#121212"})
      }
      // Phone
      if (this.state.error.phoneError != ""){
        console.log(this.state.error)
        this.setState({phoneColor: "#d65454"})
      }
      else {
        console.log(this.state.error)
        this.setState({phoneColor:"#121212"})
      } 
    });
    if (!isValid) {
      return false;
    } else {

      this.setState({ showLoginLoading: true });

      const token = await localStorage.getItem("fcmToken");

      let payload =  this.state.isWhitePaperRequest? {email,message,subject,name,phone, isWhitePaperRequest:"true" }:{email,message,subject,name,phone };

      this.props.sendMessage(payload, (response: any) => 
      {
        console.log(response);
        console.log(response.message);
        if(response.message == "Account Approved Email Sent") {
          this.setState({formSubmitted:"1"})
        }
        else{
          this.setState({formSubmitted:"2"})
        }
        this.setState(
          {
            email: "",
            message: "",
            name:"",
            subject:"",
            phone:""
          },
          () => {
            this.setState({ emailResponse: "Sent"})

            let element = document.getElementById("body-message");

            if (element) 
            {
              element.classList.add("active");

              let errorElement = document.getElementById("api-error-message");
              
              if (errorElement) {
                element.style.backgroundColor = "#00baee";
                element.style.borderColor = "#00baee";
                errorElement.innerHTML = "Sent";
              }
              setTimeout(function () {
                if (element) {
                  element.classList.remove("active");
                }
                if (errorElement) {
                  errorElement.innerHTML = "";
                }
              }, 3000);
            }

          }
        );
      });
    }
  };
  
  // reset=( )=>{

  //   this.setState({
          
  //       email: "",
  //       emailResponse: "",
  //       emailColor: "#FFFFFF",
  //       textArea: "",
  //       isWhitePaperRequest:false,
  //       showLoginLoading: false,
  //       error: {
  //       emailError: "",
  //      },
  //   })
    
      // };

  render() {

    return (
      <>
            {/* <!--=================================
    banner --> */}
    <section className="inner-banner bg-light">
      <div className="container">
        <div className="row align-items-center intro-title">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> contact-us</span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    banner --> */}

    {/* <!--=================================
    Contact-info--> */}
    <section className="space-ptb half-overlay left-position position-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-5 col-md-6 position-relative">
            {/* <!-- Get In Touch --> */}
            <div className="contact-info">
              <div className="section-title text-sm-start text-center">
                <h3>Get In Touch With Us</h3>
                <p>The best way is to develop and follow a plan. Start with your goals in mind and then work backwards to develop the plan.</p>
              </div>

              {/* <!-- Address --> */}
              <div className="feature-item d-sm-flex d-block text-sm-start text-center">
                <div className="feature-item-icon mb-sm-0 mb-4">
                  <i className="flaticon-location"></i>
                </div>
                <div className="feature-item-content">
                  <h4 className="feature-item-title">Address</h4>
                  <span>Office 01 & 02, 1st Floor # Plot # 80, Street 34, I&T Centre, G-10/1 Islamabad, 44000</span>
                </div>
              </div>
              {/* <!-- Address --> */}

              {/* <!-- Phone --> */}
              <div className="feature-item d-sm-flex d-block text-sm-start text-center">
                <div className="feature-item-icon mb-sm-0 mb-4">
                  <i className="flaticon-call"></i>
                </div>
                <div className="feature-item-content">
                  <h4 className="feature-item-title">Phone</h4>
                  <span>051-2318538</span>
                  {/* <span>+704 279 1249</span> */}
                </div>
              </div>
              {/* <!-- Phone --> */}

              {/* <!-- Email --> */}
              <div className="feature-item mb-0 d-sm-flex d-block text-sm-start text-center">
                <div className="feature-item-icon mb-sm-0 mb-4">
                  <i className="flaticon-email"></i>
                </div>
                <div className="feature-item-content">
                  <h4 className="feature-item-title">Email</h4>
                  <span>support@medileaf.com</span>
                  <span>letstalk@medileaf.com</span>
                </div>
              </div>
              {/* <!-- Email --> */}
            </div>
            {/* <!-- Get In Touch --> */}
          </div>
          <div className="col-lg-7 col-md-6 mt-md-0 mt-5">
            {/* <!-- Contact-form --> */}
            <div className="contact-form ms-lg-4">
              <div className="section-title">
                <h3>We'd love to hear from you</h3>
                <p>The price is something not necessarily defined as financial. It could be time, effort, sacrifice, money or perhaps, something else.</p>
              </div>
              {this.state.formSubmitted == "0" ? (
              <form>
                <div className="row align-items-center">
                  <div className="mb-3 col-md-6">
                    <input type="text" onChange={(e:any) => {
                      this.handleFiledUpdate("name", e.currentTarget.value)
                    }} className="form-control" id="name" placeholder="Name"/>
                    <div className='errorr' style={{ color: "red", fontSize: "12px" }}>{this.state.error.nameError}</div>
                  </div>
                  <div className="mb-3 col-md-6">
                    <input type="email" className="form-control" onChange={(e:any) => {
                      this.handleFiledUpdate("email", e.currentTarget.value)
                    }} id="email" placeholder="Email"/>
                    <div className='errorr' style={{ color: "red", fontSize: "12px" }}>{this.state.error.emailError}</div>
                  </div>
                  
                  <div className="mb-3 col-md-6">
                    <input type="text" className="form-control" onChange={(e:any) => {
                      this.handleFiledUpdate("phone", e.currentTarget.value)
                    }} id="phone" placeholder="Phone Number"/>
                    <div className='errorr' style={{ color: "red", fontSize: "12px" }}>{this.state.error.phoneError}</div>
                  </div>
                   <div className="mb-3 col-md-6">
                    <input type="text" className="form-control" id="subject" onChange={(e:any) => {
                      this.handleFiledUpdate("subject", e.currentTarget.value)
                    }} placeholder="Subject"/>
                    <div className='errorr' style={{ color: "red", fontSize: "12px" }}>{this.state.error.subjectError}</div>
                  </div>
                  <div className="mb-3 col-lg-12">
                    <textarea className="form-control" rows={5} placeholder="Message" onChange={(e:any) => {
                      this.handleFiledUpdate("message", e.currentTarget.value)
                    }}></textarea>
                    <div className='errorr' style={{ color: "red", fontSize: "12px" }}>{this.state.error.messageError}</div>
                  </div>
                  <div className="mb-3 col-sm-12 mb-0">
                    <button type="button" className="btn btn-outline-primary" onClick={(e:any) => {
                      this.handleSubmit()
                    }}>Send Message</button>
                  </div>
                </div>
              </form>): this.state.formSubmitted == "1"?
               (<div className="alert alert-success">
              <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span>Your Message has been sent Successfully.</span></li>
            </ol>
          </div>
              </div>) : this.state.formSubmitted == "2"? (<>
                <div className="alert alert-success">
              <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span>Something Went Wrong Please Try Again.</span></li>
            </ol>
          </div>
              </div>
              </> ) : (<></>)} 
            </div>
            {/* <!-- CSontact-form --> */}
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Contact-info--> */}

    {/* <!--=================================
    Map --> */}
    <section className="">
      <div className="container-fluid p-0">
        <div className="row g-0">
          <div className="col-lg-12">
            <div className="map">
              {/* <!-- iframe START --> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.5122826000934!2d73.01206705127537!3d33.669797080617165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf420345211f%3A0x4226b75d84ee44a6!2sSkillSorted!5e0!3m2!1sen!2s!4v1666856988037!5m2!1sen!2s" style={{border:"0", width: "100%", height: "100%"}} allowFullScreen></iframe>
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3320.5122826000934!2d73.01206705127537!3d33.669797080617165!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x38dfbf420345211f%3A0x4226b75d84ee44a6!2sSkillSorted!5e0!3m2!1sen!2s!4v1666856988037!5m2!1sen!2s" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> */}
              {/* <!-- iframe END --> */}
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Map --> */}
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const ContactUsModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ContactUsScreen)
);

export const ContactUs = AppLayout(ContactUsModule);




