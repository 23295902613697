import React, { Component, PureComponent } from "react";
import OnBoardingBanner from "../component/onboardingleft/onboardingbanner";
import i18n from "i18next";
import Authlayout from "../../../applayout/authlayout";
import AppLayout from "../../../applayout/applayout";
import { NavLink } from "react-router-dom";
import Utils from "../../../utils";
import { doSignup } from "../";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import firebase from "../../../utils/firebase";
import utils from "../../../utils";
import {doRegister} from "../auth.action";

interface Props {
  
  doSignup: Function;
  doRegister: Function;
  history?: any;
  signUpResponce?: any;
  role?:any;
}

interface State {
  
  // ##################
  firstName: string;
  firstNameColor:string;
  firstNameErrorShow:string;

  lastName: string;
  lastNameColor:string;
  lastNameErrorShow:string;

  email:  string;
  emailColor: string;
  emailErrorShow:string;

  password: string;
  passwordColor: string;
  passwordErrorShow:string;

  passwordConfirm: string
  passwordConfirmColor:string; 
  passwordConfirmErrorShow:string;

  country: string;
  countryColor:string;
  countryErrorShow:string;

  province:  string;
  provinceColor:string;
  provinceErrorShow:string;

  city: string;
  cityColor:string;
  cityErrorShow:string;

  address:  string;
  addressColor:string;
  addressErrorShow:string;

  postalCode: string;
  postalCodeColor:string;
  postalCodeErrorShow:string;

  phoneNumber: string;
  phoneNumberColor:string;
  phoneNumberErrorShow:string;

  companyName: string;
  companyNameColor:string;
  companyNameErrorShow:string;

  companyNumber: string;
  companyNumberColor:string;
  companyNumberErrorShow:string;

  companyRegistrationNumber:  string;
  companyRegistrationNumberColor:string;
  companyRegistrationNumberErrorShow:string;

  dob:  string;
  dobColor:string;
  dobErrorShow:string;

  accountType:  string;
  accountTypeColor:string;
  accountTypeErrorShow:string;

  showSignupLoading: boolean;

  color:string,
  // ##################

  error: {
    firstNameError: string;
    lastNameError: string;
    countryError: string;
    provinceError: string;
    cityError: string;
    addressError: string;
    postalCodeError: string;
    phoneNumberError: string;
    companyNameError: string;
    companyNumberError: string;
    CompanyRegistrationNumberError: string;
    dobError: string;
    accountTypeError: string;
    emailError: string;
    passwordError: string;
    passwordConfirmError: string;
    

  //   emailError: string;
  //   passwordError: string;
  //   nameError: string;
  //   usernameError: string;
  //  phoneError:string;
  //  genreError:string;
  //  passwordConfirmError: string;
  };
}
export default class SignupScreen extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      firstName: "",
      firstNameColor:"white",
      firstNameErrorShow:"",

      lastName: "",
      lastNameColor:"white",
      lastNameErrorShow:"",

      email: "",
      emailColor: "white",
      emailErrorShow:"",
      
      password:"",
      passwordColor: "white",
      passwordErrorShow: "",
      
      passwordConfirm: '',
      passwordConfirmColor: "white",
      passwordConfirmErrorShow: "",
      
      country: "",
      countryColor: "white",
      countryErrorShow:"",

      province: "",
      provinceColor: "white",
      provinceErrorShow:"",

      city: "",
      cityColor: "white",
      cityErrorShow:"",

      address: "",
      addressColor: "white",
      addressErrorShow:"",

      postalCode: "",
      postalCodeColor: "white",
      postalCodeErrorShow:"",

      phoneNumber: "",
      phoneNumberColor: "white",
      phoneNumberErrorShow:"",

      companyName: "",
      companyNameColor: "white",
      companyNameErrorShow:"",

      companyNumber: "",
      companyNumberColor: "white",
      companyNumberErrorShow:"",

      companyRegistrationNumber: "",
      companyRegistrationNumberColor: "white",
      companyRegistrationNumberErrorShow:"",

      dob: "",
      dobColor: "white",
      dobErrorShow: "",


      accountType: "",
      accountTypeColor: "white",
      accountTypeErrorShow: "",

      showSignupLoading: false,

      color: "black",



      error: {
        firstNameError:"",
        lastNameError: "",
        emailError: "",
        passwordError: "",
        passwordConfirmError: "",
        countryError: "",
        provinceError: "",
        cityError: "",
        addressError: "",
        postalCodeError: "",
        phoneNumberError: "",
        companyNameError: "",
        companyNumberError: "",
        CompanyRegistrationNumberError: "",
        dobError: "",
        accountTypeError: "",
        
      },
    };
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value ,[key+'Color']:'white',[key+'ErrorShow']:''});

  };

//   reset=( )=>{

// this.setState({
//       name: "",
//       nameColor:"white",
//       username: "",
//       usernameColor:"white",
//       phone:"",
//       phoneColor:"white",
//       email: "",
//       password: "",
//       genre:"",
//       genreColor:"white",
//       genreErrorShow:"",
//       passwordConfirm: '',
//       referralCode: "",
//       passwordIcon: true,
//       emailColor: "white",
//       passwordColor: "white",
//       passwordConfirmColor: "white",
//       color: "black",
//       showSignupLoading: false,
//       nameErrorShow:"",
//       emailErrorShow:"",
//       usernameErrorShow: "",
//       passwordErrorShow: "",
//       phoneErrorShow:"",
//       passwordConfirmErrorShow: "",

//       error: {
//         emailError: "",
//         nameError: "",
//         usernameError: "",
//         passwordError: "",
//         phoneError:"",
//         genreError:"",
//         passwordConfirmError: "",
//       },
// })

//   };

  // selectLang = (language: string) => {
  //   // Utils.constant.axios.defaults.headers.lng = language;
  //   // i18n.changeLanguage(language);
  //   // console.log(Utils.constant.axios.defaults.headers.lng)
  // };
  handleSubmit = async () => {
    let {
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      country,
      province,
      city,
      address,
      postalCode,
      phoneNumber,
      companyName,
      companyNumber,
      companyRegistrationNumber,
      dob,
      accountType,

      error
    } = this.state;
    let formError = Utils.constant.validateForm({
      firstName,
      lastName,
      email,
      password,
      passwordConfirm,
      country,
      province,
      city,
      address,
      postalCode,
      phoneNumber,
      companyName,
      companyNumber,
      companyRegistrationNumber,
      dob,
      accountType,
      error,
    
    });
    let isValid = Object.keys(formError).every((k) => formError[k] === "");
    this.setState({ error: Object.assign({}, formError) }, () => {
      if (this.state.error.firstNameError != "" || this.state.error.lastNameError != "" || this.state.error.emailError != ""|| this.state.error.passwordError != ""|| this.state.error.passwordConfirmError != ""|| this.state.error.countryError != ""|| this.state.error.provinceError != ""|| this.state.error.cityError != ""|| this.state.error.addressError != ""|| this.state.error.postalCodeError != ""|| this.state.error.phoneNumberError != ""|| this.state.error.companyNameError != ""|| this.state.error.companyNumberError != ""|| this.state.error.CompanyRegistrationNumberError != ""|| this.state.error.dobError != ""|| this.state.error.accountTypeError != "") {
        console.log(this.state.error)
        if (this.state.error.firstNameError != "") { this.setState({ firstNameColor: "#ed2939" }) }
        if (this.state.error.lastNameError != "") { this.setState({ lastNameColor: "#ed2939" }) }
        if (this.state.error.emailError != "") { this.setState({ emailColor: "#ed2939" }) }
        if (this.state.error.passwordError != "") { this.setState({ passwordColor: "#ed2939" }) }
        if (this.state.error.passwordConfirmError != "") { this.setState({ passwordConfirmColor: "#ed2939" }) }
        if (this.state.error.countryError != "") { this.setState({ countryColor: "#ed2939" }) }
        if (this.state.error.provinceError != "") { this.setState({ provinceColor: "#ed2939" }) }
        if (this.state.error.cityError != "") { this.setState({ cityColor: "#ed2939" }) }
        if (this.state.error.addressError != "") { this.setState({ addressColor: "#ed2939" }) }
        if (this.state.error.postalCodeError != "") { this.setState({ postalCodeColor: "#ed2939" }) }
        if (this.state.error.phoneNumberError != "") { this.setState({ phoneNumberColor: "#ed2939" }) }
        if (this.state.error.companyNameError != "") { this.setState({ companyNameColor: "#ed2939" }) }
        if (this.state.error.companyNumberError != "") { this.setState({ companyNumberColor: "#ed2939" }) }
        if (this.state.error.CompanyRegistrationNumberError != "") { this.setState({ companyRegistrationNumberColor: "#ed2939" }) }
        if (this.state.error.dobError != "") { this.setState({ dobColor: "#ed2939" }) }
        if (this.state.error.accountTypeError != "") { this.setState({ accountTypeColor: "#ed2939" }) }
        // if (this.state.error.phoneError != "") { this.setState({ phoneColor: "#ed2939" }) }
        // if (this.state.error.genreError != "") { this.setState({ genreColor: "#ed2939" }) }
        // if (this.state.error.usernameError != "") { this.setState({ usernameColor: "#ed2939" }) }
        // if (this.state.error.nameError != "") { this.setState({ nameColor: "#ed2939" }) }
        this.setState({
          firstNameErrorShow:this.state.error.firstNameError,
          lastNameErrorShow:this.state.error.lastNameError,
          emailErrorShow:this.state.error.emailError,
          passwordErrorShow:this.state.error.passwordError,
          passwordConfirmErrorShow:this.state.error.passwordConfirmError,
          countryErrorShow:this.state.error.countryError,
          provinceErrorShow:this.state.error.provinceError,
          cityErrorShow:this.state.error.cityError,
          addressErrorShow:this.state.error.addressError,
          postalCodeErrorShow:this.state.error.postalCodeError,
          phoneNumberErrorShow:this.state.error.phoneNumberError,
          companyNameErrorShow:this.state.error.companyNameError,
          companyNumberErrorShow:this.state.error.companyNumberError,
          companyRegistrationNumberErrorShow:this.state.error.CompanyRegistrationNumberError,
          dobErrorShow:this.state.error.dobError,
          accountType:this.state.error.accountTypeError,
          

          
        
        })
        this.setState({error:Object.assign({},{
          firstNameError: "",
          lastNameError: "",
          emailError: "",
          passwordError: "",
          passwordConfirmError: "",
          countryError: "",
          provinceError: "",
          cityError: "",
          addressError: "",
          postalCodeError: "",
          phoneNumberError: "",
          companyNameError: "",
          companyNumberError: "",
          CompanyRegistrationNumberError: "",
          dobError: "",
          accountTypeError: "",

          
        })})
      }
      else {
        console.log(this.state.error)
        this.setState({ firstNameColor:"white", lastNameColor:"white", emailColor: "white", passwordColor: "white", passwordConfirmColor: "white", countryColor: "white", provinceColor: "white", cityColor: "white", addressColor: "white", postalCodeColor: "white", phoneNumberColor: "white", companyNameColor: "white", companyNumberColor: "white", companyRegistrationNumberColor: "white", dobColor: "white", accountTypeColor: "white" })

      }
    });
    if (!isValid) {
      return false;
    } else {
      this.setState({ showSignupLoading: true });
      let payload = {
        firstName,
        lastName,
        email,
        password,
        country,
        province,
        city,
        address,
        postalCode,
        phoneNumber,
        companyName,
        companyNumber,
        companyRegistrationNumber,
        dob,
        accountType,
      };
      let tokens = {
        fcmToken: localStorage.getItem("fcmToken"),
      };
      console.log('this is payload')
      console.log(payload);
      if (this.state.password == this.state.passwordConfirm) {
        this.setState({ color: "black" })
      
        this.props.doRegister(payload, tokens, (response: any) => {
        console.log('back')
        console.log(response)
        if (!response.signuperror && response.userId) {
          console.log('it came back though')
            this.setState(
                {
                  //@ts-ignore
                  firstNameError: "",
                  lastNameError: "",
                  emailError: "",
                  passwordError: "",
                  passwordConfirmError: "",
                  countryError: "",
                  provinceError: "",
                  cityError: "",
                  addressError: "",
                  postalCodeError: "",
                  phoneNumberError: "",
                  companyNameError: "",
                  companyNumberError: "",
                  CompanyRegistrationNumberError: "",
                  dobError: "",
                  accountTypeError: "",
                 
                  showSignupLoading: false,
                },
                () => {
                  console.log('going in')
                  // window.location.href='/home'
                  console.log('done')
                //  this.props.history.push("/home");
                console.log(response)
                //  this.connect();
                
                  
               
                }
              );
        } else {
          this.setState({ showSignupLoading: false,emailErrorShow:"Email Already Exists",emailColor: "#ed2939" });
        }
      });
      }
      else {
        this.setState({ color: "#AF0404" })
      }
     
    }
  };
  async connect() {
    //@ts-ignore
    const eth = window.ethereum;

    // window.addEventListener('load', async () => {
    // Modern dapp browsers...
    if (eth) {
      try {
        // Request account access if needed
        //@ts-ignore

        // Acccounts now exposed
        // this.setState({ ui: "metamaskUI" })
        //@ts-ignore
        const acc = ethereum ? await ethereum.request({ method: 'eth_accounts' }) : [];

        if (acc.length > 0) {
          window.localStorage.setItem('connectedAccount', acc[0]);

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            console.log(window.location.search.replace("?returnUrl=", ""));

            this.props.history.push(window.location.search.replace("?returnUrl=", ""));
          }
          else {
            //this.props.history.push('/home');
            window.location.href='/home'
          }
        }
        else {

          let redirect = '/home';

          if (window.location.search && window.location.search.toLocaleLowerCase().includes("returnurl")) {
            redirect = redirect + window.location.search;
          }

          //this.props.history.push();
          window.location.href=redirect
        }
        // window.location.reload();
      }
      
      catch (error) {
        //@ts-ignore
        window.alert(error.message)
        // User denied account access...
      }
    }
    else {
      // window.alert('Non-Ethereum browser detected. Please install MetaMask plugin');
      window.location.href = "https://metamask.io/download";
      console.log('Non-Ethereum browser detected. You should consider trying MetaMask!');
    }
    // });

  }
  componentDidMount() {
    // let role= window.localStorage.getItem('role')
    // console.log(role)
    // console.log(this.props.role)
    console.log("hey this is me")
    // if (Utils.firebase.messaging.isSupported()) {
    //   const fcm_token = Utils.firebase.messaging();
    //   fcm_token
    //     .requestPermission()
    //     .then((token) => {
    //       return fcm_token.getToken();
    //     })
    //     .then((save_token) => {
    //       localStorage.setItem("fcmToken", save_token);
    //     })
    //     .catch((err) => {});
    // }
  }

  render() {
    
    return (
    
    <>
    <>
      {/* <div className="bg-gray-1100 pb-5 pb-lg-10">
    <div id='wrapper fixed-height'>
        <div className="no-bottom no-top" id="content">
            <div id="top"></div>
            
            <section id="subheader" className="text-light bglogin" data-bgimage="url(images/background/subheader.jpg) top">
                    <div className="center-y relative text-center">
                        <div className="container">
                            <div className="row">
                                
                                <div className="col-md-12 ">
									          <h1  style={{color:"#b6e3f9"}}>Register</h1>

                                </div>
                                <div className="clearfix"></div>
                            </div>
                        </div>
                    </div>
            </section>
            
            

            <section aria-label="section">
                <div className="container">
				
						<div className="col-md-8 offset-md-2">
				
							<div className="spacer-10"></div>
					
              <form name="contactForm" id='contact_form' className="form-border" >


                                    <div className="col-md-6">
                                        <div className="field-set ">
                                            
                                            <label style={{color:"#b6e3f9"}}>Name:</label>
                                            <div className='errorr'>{this.state.nameErrorShow}</div>
                                            <input
                                              style={{ backgroundColor: this.state.nameColor }}
                                                value={this.state.name}
                                                onChange={(e: any) => {
                                                 this.handleFiledUpdate("name", e.currentTarget.value);
                                               }}
                                            type='text' name='name' id='name' className="form-control" />
                                            
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field-set">
                                            <label style={{color:"#b6e3f9"}}>Email Address:</label>
                                            <div className='errorr'>{this.state.emailErrorShow}</div>
                                            <input
                                               style={{ backgroundColor: this.state.emailColor }}
                                                value={this.state.email}
                                                onChange={(e: any) => {
                                                 this.handleFiledUpdate("email", e.currentTarget.value);
                                               }}
                                            type='text' name='email' id='email' className="form-control" />
                                             
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field-set">
                                            <label style={{color:"#b6e3f9"}}>Choose a Username:</label>
                                            <div className='errorr'>{this.state.usernameErrorShow}</div>
                                            <input
                                              style={{ backgroundColor: this.state.usernameColor }}
                                                value={this.state.username}
                                                onChange={(e: any) => {
                                                 this.handleFiledUpdate("username", e.currentTarget.value);
                                               }}
                                            type='text' name='username' id='username' className="form-control" />
                                       
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field-set">
                                            <label style={{color:"#b6e3f9"}}>Phone:</label>
                                            <div className='errorr'>{this.state.phoneErrorShow}</div>
                                            <input
                                              style={{ backgroundColor: this.state.phoneColor }}
                                                value={this.state.phone}
                                                onChange={(e: any) => {
                                                 this.handleFiledUpdate("phone", e.currentTarget.value);
                                               }}
                                            type='text' name='phone' id='phone' className="form-control" />
                                             
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field-set">
                                            <label style={{color:"#b6e3f9"}}>Password:</label>
                                            <div className='errorr'>{this.state.passwordErrorShow}</div>
                                            <input
                                             style={{ backgroundColor: this.state.passwordColor, color: this.state.color }}
                                                value={this.state.password}
                                                onChange={(e: any) => {
                                                 this.handleFiledUpdate("password", e.currentTarget.value);
                                               }}
                                            type='password' name='password' id='password' className="form-control" />
                                             {this.state.passwordErrorShow}
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="field-set">
                                            <label style={{color:"#b6e3f9"}}>Re-enter Password:</label>
                                            <div className='errorr'>{this.state.passwordConfirmErrorShow}</div>
                                            <input
                                              value={this.state.passwordConfirm}
                                              onChange={(e: any) => {
                                               this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                                             }}
                                                  style={{ backgroundColor: this.state.passwordConfirmColor, color: this.state.color }}
                                            type='password' name='re-password' id='re-password' className="form-control" />
                                             
                                        </div>
                                    </div>


                                    <div className="col-md-4 text-center log1">
                                      <button onClick={this.handleSubmit} type="button" className=" btn btn-block mybtn btn-primary tx-tfm">Register Now</button>
                                  </div>
                                  
                            </form>
							
						</div>
                    </div>
				
            </section>
			
			
        </div>
       

        <a href="#" id="back-to-top"></a>
    </div>
    </div> */}




    
    {/* <div className="bg-gray-1100 pb-5 pb-lg-10"> */}
    {/* <div className="container">
    <div className="bg-gray-1000 py-6 pt-lg-9 pb-lg-11 mb-7 mb-lg-10">
            <div className="container px-md-5 mb-lg-2">
                <h6 className="font-size-30 font-weight-semi-bold text-center mb-1">Register</h6>
            
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb justify-content-center p-0">
                    <li className="breadcrumb-item"><a href="/home">Home</a></li>
                  
                  </ol>
                </nav>
            </div>
        </div>
        <div className="row">

            <div className="col-md-8 col-md-offset-2">
            <form name="contactForm" id='contact_form' className="form-border" >

                   
                    <fieldset>
                        <legend >Account Details </legend>

                        <div className="form-group col-md-6 ">
                            <label htmlFor="first_name">Name:</label>
                            <div className='errorr'>{this.state.nameErrorShow}</div>
                            <input
                              style={{ backgroundColor: this.state.nameColor }}
                                value={this.state.name}
                                onChange={(e: any) => {
                                  this.handleFiledUpdate("name", e.currentTarget.value);
                                }} type="text" className="form-control" name="" id="name" placeholder="Name"/>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="">Email Address:</label>
                            <div className='errorr'>{this.state.emailErrorShow}</div>
                              <input
                                style={{ backgroundColor: this.state.emailColor }}
                                value={this.state.email}
                                onChange={(e: any) => {
                                  this.handleFiledUpdate("email", e.currentTarget.value);
                                }} type="email" className="form-control" name="" id="" placeholder="Email"/>
                        </div>

                        
                        <div className="form-group col-md-6">
                            <label htmlFor="username">Choose a Username:</label>
                            <div className='errorr'>{this.state.usernameErrorShow}</div>
                            <input
                              style={{ backgroundColor: this.state.usernameColor }}
                                value={this.state.username}
                                onChange={(e: any) => {
                                  this.handleFiledUpdate("username", e.currentTarget.value);
                                }} type="text" className="form-control" name="username" id="" placeholder="Username"/>
                        </div>


                        
                        <div className="form-group col-md-6">
                            <label htmlFor="last_name">Phone:</label>
                            <div className='errorr'>{this.state.phoneErrorShow}</div>
                              <input
                                style={{ backgroundColor: this.state.phoneColor }}
                                  value={this.state.phone}
                                  onChange={(e: any) => {
                                    this.handleFiledUpdate("phone", e.currentTarget.value);
                                  }} type="number" className="form-control" name="phone_number" id="" placeholder="Phone Number"/>
                        </div>


                        <div className="form-group col-md-6">
                            <label htmlFor="password">Password</label>
                            <div className='errorr'>{this.state.passwordErrorShow}</div>
                            <input
                              style={{ backgroundColor: this.state.passwordColor, color: this.state.color }}
                                value={this.state.password}
                                onChange={(e: any) => {
                                  this.handleFiledUpdate("password", e.currentTarget.value);
                                }}type="password" className="form-control" name="" id="password" placeholder="Password"/>
                        </div>

                        <div className="form-group col-md-6">
                            <label htmlFor="confirm_password">Confirm Password</label>
                            <div className='errorr'>{this.state.passwordConfirmErrorShow}</div>
                              <input
                                value={this.state.passwordConfirm}
                                onChange={(e: any) => {
                                  this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                                }}
                                    style={{ backgroundColor: this.state.passwordConfirmColor, color: this.state.color }} type="password" className="form-control" name="" id="confirm_password" placeholder="Confirm Password"/>
                        </div>


                    </fieldset>

                   

                    <div className="form-group">
                        <div className="col-md-12">
                            <div className="checkbox">
                                <label>
                                    <input type="checkbox" value="" id=""/>
                                    I accept the <a href="#">terms and conditions</a>.
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="form-group">
                        <div className="col-md-12">
                        <button onClick={this.handleSubmit} type="button" className="btn btn-primary">
                                Register
                            </button>
                            <a href="#">Already have an account?</a>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div> */}
    {/* </div> */}
    </>
    


    {/* <div className="modal fade" id="SignupModal" tabIndex= {-1} role="dialog" aria-hidden="true">
      <div className="modal-dialog modal-sm" role="document">
          <div className="modal-content">
              <button type="button" className="close position-absolute top-0 right-0 z-index-2 mt-3 mr-3" data-dismiss="modal" aria-label="Close" onClick={()=>{
                this.reset()
              }}>
                  <svg aria-hidden="true" className="mb-0" width="14" height="14" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                      <path fill="currentColor" d="M11.5,9.5l5-5c0.2-0.2,0.2-0.6-0.1-0.9l-1-1c-0.3-0.3-0.7-0.3-0.9-0.1l-5,5l-5-5C4.3,2.3,3.9,2.4,3.6,2.6l-1,1 C2.4,3.9,2.3,4.3,2.5,4.5l5,5l-5,5c-0.2,0.2-0.2,0.6,0.1,0.9l1,1c0.3,0.3,0.7,0.3,0.9,0.1l5-5l5,5c0.2,0.2,0.6,0.2,0.9-0.1l1-1 c0.3-0.3,0.3-0.7,0.1-0.9L11.5,9.5z"/>
                  </svg>
              </button>

        
              <div className="modal-body">
                  <form className="js-validate">
                      <div id="signup">

                          <div className="text-center mb-7">
                              <h3 className="mb-0">Register YourSelf</h3>
                              <p>Register YourSelf to Experirence.</p>
                          </div>

                          <div className="js-form-message mb-4">
                              <label className="input-label">Name</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.nameErrorShow}</div>
                                  <input
                                  style={{ backgroundColor: this.state.nameColor }}
                                    value={this.state.name}
                                    onChange={(e: any) => {
                                      this.handleFiledUpdate("name", e.currentTarget.value);
                                    }} type="text" className="form-control" name="" id="name" placeholder="Name"/>
                              </div>
                          </div>

                          <div className="js-form-message mb-4">
                              <label className="input-label">Email Address</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.emailErrorShow}</div>
                                  <input
                                    style={{ backgroundColor: this.state.emailColor }}
                                    value={this.state.email}
                                    onChange={(e: any) => {
                                      this.handleFiledUpdate("email", e.currentTarget.value);
                                    }} type="email" className="form-control" name="" id="" placeholder="Email"/>
                                </div>
                          </div>


                          <div className="js-form-message mb-4">
                              <label className="input-label">Choose a Username</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.usernameErrorShow}</div>
                                  <input
                                  style={{ backgroundColor: this.state.usernameColor }}
                                  value={this.state.username}
                                  onChange={(e: any) => {
                                  this.handleFiledUpdate("username", e.currentTarget.value);
                                  }} type="text" className="form-control" name="username" id="" placeholder="Username"/>
                              </div>
                          </div>

                          <div className="js-form-message mb-4">
                              <label className="input-label">Phone</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.phoneErrorShow}</div>
                                  <input
                                    style={{ backgroundColor: this.state.phoneColor }}
                                    value={this.state.phone}
                                    onChange={(e: any) => {
                                      this.handleFiledUpdate("phone", e.currentTarget.value);
                                    }} type="number" className="form-control" name="phone_number" id="" placeholder="Phone Number"/>
                              </div>
                          </div>

                          {this.props.role=="Seller"? (<>
                          <div className="js-form-message mb-4">
                              <label className="input-label">Genre</label>

                              <div className="field-set">
                        
                                <div className='errorr'>{this.state.genreErrorShow}</div>
                                  <select className="selection form-control mb-3" aria-label="Default select example" 
                                  value={this.state.genre} onChange={(e: any) => {
                                      this.handleFiledUpdate("genre", e.currentTarget.value) ; 
                                  }}>
                                
                                      <option >Select Genre Catagory</option>
                             
                                      <option>Actor</option>
                                      <option>Singer</option>
                                      <option>Model</option>
                                      <option>Comedian </option>
                                      </select >

                                  </div></div></>):
                                  (<></>)}
                           
                       



                          <div className="js-form-message mb-4">
                              <label className="input-label">Password</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.passwordErrorShow}</div>
                                  <input
                                  style={{ backgroundColor: this.state.passwordColor, color: this.state.color }}
                                  value={this.state.password}
                                  onChange={(e: any) => {
                                    this.handleFiledUpdate("password", e.currentTarget.value);
                                  }}type="password" className="form-control" name="" id="password" placeholder="Password"/>
                              </div>
                          </div>

                          <div className="js-form-message mb-4">
                              <label className="input-label">Confirm Passwrod</label>

                              <div className="field-set">
                                <div className='errorr'>{this.state.passwordConfirmErrorShow}</div>
                                  <input
                                    value={this.state.passwordConfirm}
                                    onChange={(e: any) => {
                                    this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                                    }}
                                    style={{ backgroundColor: this.state.passwordConfirmColor, color: this.state.color }} type="password" className="form-control" name="" id="confirm_password" placeholder="Confirm Password"/>
                              </div>
                          </div>

                          <div className="mb-3">
                              <button onClick={this.handleSubmit} type="button" className="btn btn-sm btn-primary btn-block">Register</button>
                          </div>

                      
                      </div>

                      
                  </form>
              </div>
              
          </div>
      </div>
  </div> */}
  


  <main className="main">
            <nav className="breadcrumb-nav">
                <div className="container">
                    <ul className="breadcrumb">
                        <li><a href="demo1.html"><i className="d-icon-home"></i></a></li>
                        <li><a href="shop.html">Riode Shop</a></li>
                        <li>My Account</li>
                    </ul>
                </div>
            </nav>
            <div className="page-content mt-6 pb-2 mb-10">
                <div className="container">
                    <div className="login-popup">
                        <div className="form-box">
                            <div className="tab tab-nav-simple tab-nav-boxed form-tab">
                                <ul className="nav nav-tabs nav-fill align-items-center border-no justify-content-center mb-5"
                                    role="tablist">
                                    
                                    <li className="nav-item">
                                        <a className="nav-link border-no active lh-1 ls-normal">Register</a>
                                    </li>
                                </ul>
                                <div className="tab-content">
                                    
                                    {/* <div className="tab-pane active" id="register"> */}
                                        <form action="#">
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("firstName", e.currentTarget.value);
                }} type="text" className="form-control"
                                                    name="register-email" placeholder="First Name *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("lastName", e.currentTarget.value);
                }} type="text" className="form-control"
                                                    name="register-password" placeholder="Last Name *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("email", e.currentTarget.value);
                }} type="email" className="form-control"
                                                    name="register-email" placeholder="Your Email Address *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("password", e.currentTarget.value);
                }}  type="password" className="form-control" 
                                                    name="register-password" placeholder="Password *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("passwordConfirm", e.currentTarget.value);
                }}  type="password" className="form-control" 
                                                    name="register-password" placeholder="Password *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("country", e.currentTarget.value);
                }} type="text" className="form-control" 
                                                    name="register-password" placeholder="Country *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("province", e.currentTarget.value);
                }} type="text" className="form-control"
                                                    name="register-password" placeholder="Province *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("city", e.currentTarget.value);
                }} type="text" className="form-control" 
                                                    name="register-password" placeholder="City *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("address", e.currentTarget.value);
                }} type="text" className="form-control" 
                                                    name="register-password" placeholder="Address *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("postalCode", e.currentTarget.value);
                }} type="number" className="form-control"
                                                    name="register-password" placeholder="Postal Code *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("phoneNumber", e.currentTarget.value);
                }} type="number" className="form-control"
                                                    name="register-password" placeholder="Phone Number *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("companyName", e.currentTarget.value);
                }} type="text" className="form-control" 
                                                    name="register-password" placeholder="Company Name *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("companyNumber", e.currentTarget.value);
                }} type="number" className="form-control"
                                                    name="register-password" placeholder="Company Number *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("companyRegistrationNumber", e.currentTarget.value);
                }} type="number" className="form-control" 
                                                    name="register-password" placeholder="Company Registration Number *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("dob", e.currentTarget.value);
                }} type="date" className="form-control" 
                                                    name="register-password" placeholder="Enter Your Date of Birth *" required />
                                            </div>
                                            <div className="form-group">
                                                <input onChange={(e) => {
                  this.handleFiledUpdate("accountType", e.currentTarget.value);
                }} type="text" className="form-control" 
                                                    name="register-password" placeholder="Private/Company *" required />
                                            </div>
                                            
                                            <div className="form-footer">
                                                <div className="form-checkbox">
                                                    <input type="checkbox" className="custom-checkbox" id="register-agree"
                                                        name="register-agree" required />
                                                    <label className="form-control-label" htmlFor="register-agree">I agree to
                                                        the
                                                        privacy policy</label>
                                                </div>
                                            </div>
                                            <button className="btn btn-dark btn-block btn-rounded"
                                               onClick={this.handleSubmit} type="button">Register</button>
                                        </form>
                                        <div className="form-choice text-center">
                                            <label className="ls-m">or Register With</label>
                                            <div className="social-links">
                                                <a href="#"
                                                    className="social-link social-google fab fa-google border-no"></a>
                                                <a href="#"
                                                    className="social-link social-facebook fab fa-facebook-f border-no"></a>
                                                <a href="#"
                                                    className="social-link social-twitter fab fa-twitter border-no"></a>
                                            </div>
                                        </div>
                                    {/* </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </main>




    
    </>
    );
  }
}

function mapStateToProps(state: any) {
  return {
    signUpResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    doSignup: (payload: any, tokens: any, callback: Function) =>
      dispatch(doSignup(payload, tokens, callback)),
      doRegister: (payload: any, token: string, callback: Function) =>
    dispatch(doRegister(payload,  callback)),
  };
}

const SignupModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(SignupScreen)
);

export const Signup = AppLayout(SignupModule);
