// import React from "react";
   //@ts-ignore
// import * as firebase from "firebase";
import  firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const firebaseConfig = {
  apiKey:
    process.env.REACT_FIREBASE_API_KEY ||
    "AIzaSyBDBev0fSo3UMCRYc_w_9AaN6ulSptemoQ",
  authDomain:
    process.env.REACT_FIREBASE_DOMAIN || "grabjobs-live.firebaseapp.com",
  databaseURL:
    process.env.REACT_FIREBASE_DATABASE_URL ||
    "https://grabjobs-live.firebaseio.com",
  projectId: process.env.REACT_FIREBASE_PROJECT_ID || "grabjobs-live",
  storageBucket:
    process.env.REACT_FIREBASE_STORAGE_BUCKER || "grabjobs-live.appspot.com",
  messagingSenderId:
    process.env.REACT_FIREBASE_MESSAGE_SENDER_ID || "989600495713",
  appId:
    process.env.REACT_FIREBASE_APP_ID ||
    "1:46572285784:web:bca2d4ecf258bfc61c3f2d",
  measurementId: process.env.REACT_FIREBASE_MESURMENT_ID || "G-GVDQ790LGK",
};

// const firebaseConfig = {
//   apiKey: "AIzaSyAbhfccdySDclS7V_GR7fbqqtyOupvwhGQ",
//   authDomain: "grabjobs-2020.firebaseapp.com",
//   databaseURL: "https://grabjobs-2020.firebaseio.com",
//   projectId: "grabjobs-2020",
//   storageBucket: "grabjobs-2020.appspot.com",
//   messagingSenderId: "613522874190",
//   appId: "1:613522874190:web:73e51d73065ef7648a881f",
//   measurementId: "G-5MQNL90XSE",
// };

firebase.initializeApp(firebaseConfig);

export default firebase;
