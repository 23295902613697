import './App.css';
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import Store from "./store";
import Router from "./router";
import i18n from "./i18n";
import React from 'react';
function App() {
  return (
    <Provider store={Store}>
    <I18nextProvider i18n={i18n}>
      <Router />
    </I18nextProvider>
  </Provider>
  );
}

export default App;
