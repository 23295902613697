import Utils from "../../utils";
import { JobListsModal, SelectCategoryModel } from "../../modal";
export function jobLists(
  payload: any,
  id: any,
  token: string,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.jobLists;
    let filterObj = {
      page: payload.page ? payload.page : 0,
    };
    let restUrl: string;
    restUrl = `page=${filterObj.page}&recruiterId=${id}`;

    Utils.api.getApiCall(
      endPoint,
      restUrl,
      token,
      function (response: any) {
        if (response.status) {
          let data: JobListsModal = response.data.data;
          dispatch({
            type: Utils.actionName.HANDEL_JOB_LIST,
            payload: { data, loading: "compleated", error: false },
          });
          callback(response.data);
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
        dispatch({
          type: Utils.actionName.HANDEL_JOB_LIST,
          payload: { data: error.data.message, error: true },
        });
      }
    );
  };
}

/***************************************Select Category*********************************************/
export function SelectCategory(
  payload: any,
  callback: Function = function () {}
) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.SelectCategory;
    Utils.api.getApiCall(
      endPoint,
      ``,
      payload,
      function (response: any) {
        if (response.status) {
          let data: SelectCategoryModel = response.data.data;
          dispatch({
            type: Utils.actionName.SELECT_CATEGORY,
            payload: { data },
          });
          callback();
        }
      },
      function (error: any) {
        if (error.status === 501) {
          Utils.constant.apiErrorMessage(error.data.message);
        }
      }
    );
  };
}
/************************************************get transection history*************************** */
export function sendMessage(payload: any, callback: Function = function () {}) {
  return (dispatch: Function, getState: Function) => {
    let hasConnection = Utils.constant.hasConnection();
    console.log('in1')
    if (!hasConnection) {
      return false;
    }
    Utils.constant.handleOverlayLoader(true);
    let endPoint = Utils.endpoints.contactus;
    payload.device_id = "device123";
    Utils.api.postApiCall(
      endPoint,
      payload,
      function (response: any) {
        if (
          response.status &&
          response.data &&
          response.data.code &&
          response.data.code === 200
        ) {
          let data: any = response.data.data;
          console.log('in2')
          callback(data);
        } else if (response.status && response.data) {
          let data: any = response.data;
          let error = data.code && data.code != 200 ? data.message : "";
          error = error ? error : "Something went wrong";
          Utils.constant.apiErrorMessage(error);
          console.log('in3')
          callback({ loginerror: true });
        }
      },
      function (error: any) {
        console.log('in4')
        Utils.constant.apiErrorMessage(error.data.message);
        callback({ loginerror: true });
      }
    );
  };
}
