import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/otherlayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '../'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;

}
interface State {
  email: string;
  showLoginLoading: boolean;
  emailColor: string;
  textArea: string;
  emailResponse: string;
  isWhitePaperRequest:boolean;
  error: {
    emailError: string;
  };
}

export class ServicesScreen extends PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      emailResponse: "",
      emailColor: "#FFFFFF",
      textArea: "",
      isWhitePaperRequest:false,
      showLoginLoading: false,
      error: {
        emailError: "",
      },
    };
  }

  componentDidMount() {

    console.log(window.location.search);
      
    if(window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper"))
    {
      this.setState({isWhitePaperRequest:true});
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => 
  {
    console.log('handled')

    let { email, textArea, error } = this.state;

    let formError = Utils.constant.validateForm({ email, textArea, error });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => 
    {
      if (this.state.error.emailError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "#121212" })
      }

    });
    if (!isValid) {
      return false;
    } else {

      this.setState({ showLoginLoading: true });

      const token = await localStorage.getItem("fcmToken");

      let payload =  this.state.isWhitePaperRequest? {email,textArea,isWhitePaperRequest:"true" }:{email,textArea };

      this.props.sendMessage(payload, (response: any) => 
      {
        this.setState(
          {
            email: "",
            textArea: ""
          },
          () => {
            this.setState({ emailResponse: "Sent" })

            let element = document.getElementById("body-message");

            if (element) 
            {
              element.classList.add("active");

              let errorElement = document.getElementById("api-error-message");
              
              if (errorElement) {
                element.style.backgroundColor = "#00baee";
                element.style.borderColor = "#00baee";
                errorElement.innerHTML = "Sent";
              }
              setTimeout(function () {
                if (element) {
                  element.classList.remove("active");
                }
                if (errorElement) {
                  errorElement.innerHTML = "";
                }
              }, 3000);
            }

          }
        );
      });
    }
  };
  
  // reset=( )=>{

  //   this.setState({
          
  //       email: "",
  //       emailResponse: "",
  //       emailColor: "#FFFFFF",
  //       textArea: "",
  //       isWhitePaperRequest:false,
  //       showLoginLoading: false,
  //       error: {
  //       emailError: "",
  //      },
  //   })
    
      // };

  render() {

    return (
    <>
    {/* <!--=================================
    banner --> */}
    <section className="inner-banner bg-light">
      <div className="container">
        <div className="row align-items-center intro-title">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> Services </span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    banner --> */}

    {/* <!--=================================
    Departments --> */}
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          {/* <!-- Departments-01 START --> */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/01.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-pregnant"></i></div>
                <h5 className="text-primary">Billing Services</h5>
                <p className="mb-0">Our end-to-end medical billing services provide maximum reimbursement for our clients while reducing overall costs. We don’t just monitor your account we actively manage it.</p>
              </div>
            </div>
          </div>
          {/* <!-- Departments-01 END -->

          <!-- Departments-02 START --> */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/02.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-joint"></i></div>
                <h5 className="text-primary">Medical Coding Services</h5>
                <p className="mb-0">Team of certified medical coders offers customized ICD, CPT and HCPCS coding solutions for any type of medical facility. Contact us for your coding needs!</p>
              </div>
            </div>
          </div>
          {/* <!-- Departments-02 END -->

          <!-- Departments-03 START --> */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/03.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-brain"></i></div>
                <h5 className="text-primary">Credentialing</h5>
                <p className="mb-0">Let the insurance companies do your marketing for you! As an in-network provider, you’ll get more patients without having to do any advertising. Our team will help you get accredited by more insurance companies.</p>
              </div>
            </div>
          </div>
          {/* <!-- Departments-03 END -->

          <!-- Departments-04 START --> */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/04.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-eye"></i></div>
                <h5 className="text-primary">AR Recovery Services</h5>
                <p className="mb-0">Alpha Medical Billing specializes in Aging A/R recovery. We pursue every old claim by assigning our dedicated team of Aging A/R specialists. If your in-house billing team can’t keep up with Aging, we can help!</p>
              </div>
            </div>
          </div>
          {/* <!-- Departments-04 END -->

          <!-- Departments-05 START --> */}
          <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/05.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-medicine"></i></div>
                <h5 className="text-primary">Consulting</h5>
                <p className="mb-0">Even if Alpha Medical Billing isn’t your medical billing company, we can help you get the most from your medical billing processes.</p>
              </div>
            </div>
          </div>
          {/* <!-- Departments-05 END -->

          <!-- Departments-06 START --> */}
          {/* <div className="col-lg-4 col-md-6 mb-4">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/06.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-tooth-1"></i></div>
                <h5 className="text-primary">Dental Care</h5>
                <p className="mb-0">Use a past defeat as a motivator. Remind yourself you have nowhere</p>
              </div>
            </div>
          </div> */}
          {/* <!-- Departments-06 END -->

          <!-- Departments-07 START --> */}
          {/* <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/07.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-medical-report"></i></div>
                <h5 className="text-primary">Pediatric</h5>
                <p className="mb-0">It must come from within as the natural product of your desire to achieve something.</p>
              </div>
            </div>
          </div> */}
          {/* <!-- Departments-07 END -->

          <!-- Departments-08 START --> */}
          {/* <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/08.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-kidneys"></i></div>
                <h5 className="text-primary">Urology</h5>
                <p className="mb-0">Something that someone else can give you you are the only one with the power to motivate you.</p>
              </div>
            </div>
          </div> */}
          {/* <!-- Departments-08 END -->

          <!-- Departments-09 START --> */}
          {/* <div className="col-lg-4 col-md-6 mb-0">
            <div className="departments-items h-100">
              <div className="departments-img">
                <img className="img-fluid" src="images/departments/09.jpg" alt=""/>
              </div>
              <div className="departments-content">
                <div className="departments-icon"><i className="flaticon-electrocardiogram"></i></div>
                <h5 className="text-primary">Cardiology</h5>
                <p className="mb-0">I truly believe Augustine’s words are true and if you look at history you know it is true.</p>
              </div>
            </div>
          </div> */}
          {/* <!-- Departments-09 END --> */}
        </div>
      </div>
    </section>
    {/* <!--=================================
    Departments --> */}

    {/* <!--=================================
    Testimonial --> */}
    {/* <section className="space-pb">
      <div className="container" style={{backgroundImage: `url(images/pattern.png);`}}>
        <div className="row align-items-center">
          <div className="col-lg-12">
            
            <div className="owl-carousel testimonial" data-nav-arrow="false" data-items="1" data-md-items="1" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0" data-autoheight="true" style={{backgroundImage: `url(images/pattern.png);`}}>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/09.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   I have gotten at least 50 times the value from Medileaf. I will let my mum know about this, she could really make use of Medileaf! Wow what great service, I love it! Medileaf is the real deal!
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Alice Williams</h6>
                      <span>- Mother.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/04.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   We were treated like royalty. Needless to say we are extremely satisfied with the results. Thank you for making it painless, pleasant and most of all hassle free! It fits our needs perfectly.
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Felica Queen</h6>
                      <span>- Cancer client.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/03.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   We've seen amazing results already. Since I invested in Medileaf I made over 100,000 dollars profits. It's the perfect solution for our business. I was amazed at the quality of Medileaf.
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Paul Flavius</h6>
                      <span>- Heather.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/05.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   I love Medileaf. I have gotten at least 50 times the value from Medileaf. I STRONGLY recommend Medileaf to EVERYONE interested in running a successful online business!
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Harry Russell</h6>
                      <span>- Surgery client.</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* <!--=================================
    Testimonial -->

    <!--=================================
    Schedule --> */}
    <section className="mb-lg-n6 mb-md-n5 pb-0 pb-sm-4 pb-lg-0" >
      <div className="container" >
        <div className="row">
          <div className="col-sm-12">
            <div className="schedule shadow">
              <div className="row g-0">
                <div className="col-lg-4">
                  <div className="schedule-morden bg-white">
                    <div className="icon">
                      <i className="flaticon-emergency-call"></i>
                    </div>
                    <div className="schedule-morden-content" >
                      <h6>Contact</h6>
                      <span className="phone-number h4">051-2318538</span>
                      <p className="mb-0">Commitment is something that comes from understanding that everything has its price and then having the willingness to pay that price.</p>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="schedule-morden bg-white">
                    <div className="icon">
                      <i className="flaticon-clock"></i>
                    </div>
                    <div className="schedule-morden-content">
                      <h6>Opening Hours</h6>
                      <div className="opening-hourse">
                        <ul className="list-unstyled">
                          <li>
                            <span>Monday – Friday</span>
                            <div className="time"> 08.30 - 18.30 </div>
                          </li>
                          <li>
                            <span>Saturday</span>
                            <div className="time"> 09.30 - 17.30 </div>
                          </li>
                          <li>
                            <span>Sunday</span>
                            <div className="time"> 09.30 - 15.30 </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="schedule-morden last bg-white">
                    <div className="icon">
                      <i className="flaticon-calendar"></i>
                    </div>
                    <div className="schedule-morden-content">
                      <h6>Timetable</h6>
                      <p className="mb-4">This is important because nobody wants to put significant effort into something, only to find out after the fact that the price was too high.</p>
                      <a className="btn btn-outline-primary" href="timetable.html"><i className="fa fa-address-book"></i>View Timetable</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Schedule --> */}
    </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const ServicesModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(ServicesScreen)
);

export const Services = AppLayout(ServicesModule);
