import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils";
import { GetAllUsers, GetPromo } from "../producView.action";
// import {EditActorProfile} from "../../edit-actor-profile/screen/edit-actor-profile";
// import {updateProfile} from "../../edit-actor-profile/edit-actor-profile.action"
// import {getUserProfileData} from "../../edit-actor-profile/edit-actor-profile.action"
// import {UserDataModal} from "../../../modal"
// import { placeOrder,getUserProfileData } from "..";



interface Props {
    // changeLanguage?: any;
    t?: any;
    history?: any;
    GetAllUsers: Function;
    GetPromo: Function;
    // getUserProfileData:Function;
    // actorParamId?:number;
}

interface State {
    actorsList: any;
    // username: string;
    // email: string;
    // fullname:string;
   
    // phone:string;
    // genre:string;
   
    // serviceCharges:string;
    // actor_name:string;
    // bio:string;
    // img:string;
    // forWhomRadio:string;
    // checkedRadio:any;

    // actorId: string;
   
    // customerId: string;
    // occasion: string;
    // message: string;
    // imageSrc:string;
    // to: string;
    // from: string;
    // toColor: string;
    // fromColor: string;
    
    // messageColor: string;
    // role:string;
    // messageErrorShow:string;
    // actorParamId:number;
    
    // toErrorShow:string;
    // fromErrorShow:string;
    // forWhom: string;
    // customerName: string;
    // customerEmail: string;
    error: {
    messageError: string;
    toError: string;
    fromError: string;
    };


 }
export class ProductViewScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            actorsList: [],
            // email: "",
            // genre:"",
            
            // phone:"",
          
            // serviceCharges:"",
            // actor_name:"",
            // bio:"",
            // img:"",
            // forWhomRadio:"none",
            // actorId: "",
            // username:"",
            // imageSrc:"",
            // fullname:"",
            // customerId: "",
            // occasion: "Others",
            // message: "",
            // to:"",
            //@ts-ignore
            // actorParamId:'',
            // from:"",
            // checkedRadio:"My Self",
            // messageColor:"",
            // toColor:"",
            // role:"",
            // fromColor:"",
            // messageErrorShow:"",
            // toErrorShow:"",
            // fromErrorShow:"",
            // forWhom: "My Self",
            // customerName: "",
            // orderStatus: "",

            // customerEmail: "",
            error: {
            messageError: "",
            toError: "",
            fromError:""
            },

        };
    }


    // handleFiledUpdate = (key: string, value: any) => {
    //     //@ts-ignore
    //     this.setState({ [key]: value ,[key+'Color']:'white',[key+'ErrorShow']:''});

    // };

//     handleSubmit = async () => {
//         // console.log("hey baby")
//         let { occasion, message, checkedRadio, to, from, error} = this.state;
//         let formError =this.state.checkedRadio=="My Self"? Utils.constant.validateForm({ message,error}):Utils.constant.validateForm({ to,from,message,error});
//         console.log("error is", error)
//         let isValid = Object.keys(formError).every((k) => formError[k] === "");
//         this.setState({ error: Object.assign({}, formError) }, () => {
//         if (this.state.error.messageError != "") {
//             console.log(this.state.error)
//             if (this.state.error.messageError != "") { this.setState({ messageColor: "#ed2939" }) }
           
//             this.setState({
//                 messageErrorShow:this.state.error.messageError,
               
//             })
//         }
//         if (this.state.error.toError!=""&&this.state.error.fromError!="" ) {
//             console.log(this.state.error)
            
//             if (this.state.error.toError != "") { this.setState({ toColor: "#ed2939" }) }
//             if (this.state.error.fromError != "") { this.setState({ fromColor: "#ed2939" }) }
//             this.setState({
             
//                 toErrorShow:this.state.error.toError,
//                 fromErrorShow:this.state.error.fromError
//             })
//         }
//     });
//         // console.log("hey this is error",messageErrorShow)
    
//         if (!isValid) {
//         return false;
//         } else {
//         console.log("handle submit pressed");

//         const actor_id = await localStorage.getItem("actor_id");
//         const user_id = await localStorage.getItem("userId");
//         const user_name = await localStorage.getItem("username");
//         const actor_name = await localStorage.getItem("actor_name");
//         // const user_email = await localStorage.getItem("email");


//         let payload = {
//             occasion,
//             message,
//             forWhom:checkedRadio,
//             to,
//             from,
//             actorId : actor_id,
//             customerId: user_id,
//             customerName: user_name,
//             actorName: actor_name,
//             orderDate:new Date()

//             // orderStatus:this.state.orderStatus
            
//         };
//         this.props.placeOrder(payload, (data: any, data2: any) => {
//             console.log(data);
//            // window.open(data2.url)
//             window.location.href=data2.url;
// //             var myHeaders = new Headers();
// //             myHeaders.append("Content-Type", "application/json");

// //             var raw = JSON.stringify({
// //             "orderId": data2.orderId,
// //             "merchantId": data2.merchantId
// //             });

// //             var requestOptions = {
// //             method: 'POST',
// //             headers: myHeaders,
// //             body: raw,
// //             redirect: 'follow'
// //             };
// // //@ts-ignore
// //             fetch("https://payment.simpaisa.com/card/finalize", requestOptions)
// //             .then(response => response.text())
// //             .then(result => console.log(result))
// //             .catch(error => console.log('error', error))
//          });
//     };
// }


getAllUsers = async () => {
        console.log('All Actors are here')
        this.props.GetAllUsers({}, (response: any) => {
            console.log('back')
            console.log(response)
            this.setState({ actorsList: response }, ()=>{

                // //@ts-ignore
                // window.SlikSlider();


            });
        });

    };

    // getPromo = async () => {
    //     console.log('All Actors are here')
    //     this.props.GetPromo({}, (response: any) => {
    //         console.log('back')
    //         console.log(response)
    //         // this.setState({ actorsList: response }, ()=>{

    //         //     // //@ts-ignore
    //         //     // window.SlikSlider();


    //         // });
    //     });

    // };




    userid = localStorage.getItem("userId");
    // localStorage.setItem("languageSelected", 'en');

    

  async  componentDidMount() {    const userToken =  localStorage.getItem("accessToken");

//   if (userToken) {
//       console.log('sending request')
//   }
//       else {

//           window.location.href = "/";
//       }

      this.getAllUsers()
// this.getPromo()




    }


    render() {
        return (
        <>
       <main  className="main mt-6 single-product">
			<div  className="page-content mb-10 pb-6">
				<div  className="container">
					<div  className="product product-single row mb-2">
						<div  className="col-md-6">
							<div  className="product-gallery pg-vertical product-gallery-sticky">
								<div  className="product-single-carousel owl-carousel owl-theme owl-nav-inner row cols-1">
									<figure  className="product-image">
										<img src={require("../../../assets/images/demos/demo2/product/product-1-580x652.jpg")}
											// data-zoom-image="images/demos/demo2/product/product-1-800x900.jpg"
											alt="Blue Pinafore Denim Dress" width="800" height="900"
											style={{backgroundColor: "#f2f3f5"}} />
									</figure>
									<figure  className="product-image">
										<img src={require("../../../assets/images/demos/demo2/product/product-2-580x652.jpg")}
											// data-zoom-image="images/demos/demo2/product/product-2-800x900.jpg"
											alt="Blue Pinafore Denim Dress" width="800" height="900"
											style={{backgroundColor: "#f2f3f5"}} />
									</figure>
									<figure  className="product-image">
										<img src={require("../../../assets/images/demos/demo2/product/product-3-580x652.jpg")}
											// data-zoom-image="images/demos/demo2/product/product-3-800x900.jpg"
											alt="Blue Pinafore Denim Dress" width="800" height="900"
											style={{backgroundColor: "#f2f3f5"}}/>
									</figure>
									<figure  className="product-image">
										<img src={require("../../../assets/images/demos/demo2/product/product-4-580x652.jpg")}
											// data-zoom-image="images/demos/demo2/product/product-4-800x900.jpg"
											alt="Blue Pinafore Denim Dress" width="800" height="900"
											style={{backgroundColor: "#f2f3f5"}} />
									</figure>
									<figure  className="product-image">
										<img src={require("../../../assets/images/demos/demo2/product/product-5-580x652.jpg")}
											// data-zoom-image="images/demos/demo2/product/product-5-800x900.jpg"
											alt="Blue Pinafore Denim Dress" width="800" height="900"
											style={{backgroundColor: "#f2f3f5"}} />
									</figure>
								</div>
								<div  className="product-thumbs-wrap">
									<div  className="product-thumbs">
										<div  className="product-thumb active">
											<img src={require("../../../assets/images/demos/demo2/product/product-1-109x122.jpg")}
												alt="product thumbnail" width="109" height="122"
												style={{backgroundColor: "#f2f3f5"}} />
										</div>
										<div  className="product-thumb">
											<img src={require("../../../assets/images/demos/demo2/product/product-2-109x122.jpg")}
												alt="product thumbnail" width="109" height="122"
												style={{backgroundColor: "#f2f3f5"}} />
										</div>
										<div  className="product-thumb">
											<img src={require("../../../assets/images/demos/demo2/product/product-3-109x122.jpg")}
												alt="product thumbnail" width="109" height="122"
												style={{backgroundColor: "#f2f3f5"}} />
										</div>
										<div  className="product-thumb">
											<img src={require("../../../assets/images/demos/demo2/product/product-4-109x122.jpg")}
												alt="product thumbnail" width="109" height="122"
												style={{backgroundColor: "#f2f3f5"}} />
										</div>
										<div  className="product-thumb">
											<img src={require("../../../assets/images/demos/demo2/product/product-5-109x122.jpg")}
												alt="product thumbnail" width="109" height="122"
												style={{backgroundColor: "#f2f3f5"}} />
										</div>
									</div>
									<button  className="thumb-up disabled"><i  className="fas fa-chevron-left"></i></button>
									<button  className="thumb-down disabled"><i  className="fas fa-chevron-right"></i></button>
								</div>
							</div>
						</div>
						<div  className="col-md-6">
							<div  className="product-details">
								<div  className="product-navigation">
									<ul  className="breadcrumb breadcrumb-lg">
										<li><a href="demo2.html"><i  className="d-icon-home"></i></a></li>
										<li><a href="#"  className="active">Products</a></li>
										<li>Detail</li>
									</ul>

									<ul  className="product-nav">
										<li  className="product-nav-prev">
											<a href="#">
												<i  className="d-icon-arrow-left"></i> Prev
												<span  className="product-nav-popup">
													<img src={require("../../../assets/images/product/product-thumb-prev.jpg")}
														alt="product thumbnail" width="110" height="123"/>
													<span  className="product-name">Sed egtas Dnte Comfort</span>
												</span>
											</a>
										</li>
										<li  className="product-nav-next">
											<a href="#">
												Next <i  className="d-icon-arrow-right"></i>
												<span  className="product-nav-popup">
													<img src={require("../../../assets/images/product/product-thumb-next.jpg")}
														alt="product thumbnail" width="110" height="123"/>
													<span  className="product-name">Sed egtas Dnte Comfort</span>
												</span>
											</a>
										</li>
									</ul>
								</div>

								<h1  className="product-name">Men's Fashion T Shirt</h1>
								<div  className="product-meta">
									SKU: <span  className="product-sku">12345670</span>
									BRAND: <span  className="product-brand">The Northland</span>
								</div>
								<div  className="product-price mb-2">$139.00</div>
								<div  className="ratings-container">
									<div  className="ratings-full">
										<span  className="ratings" style={{width: "80%"}}></span>
										<span  className="tooltiptext tooltip-top"></span>
									</div>
									<a href="#product-tab-reviews"  className="link-to-tab rating-reviews">( 6 reviews )</a>
								</div>
								<p  className="product-short-desc">Sed egestas, ante et vulputate volutpat, eros pede semper
									est, vitae luctus metus libero eu augue. Morbi purus liberpuro ate vol faucibus
									adipiscing.</p>

								<hr  className="product-divider"/>

								<div  className="product-form product-qty">
									<div  className="product-form-group">
										<div  className="input-group mr-2">
											<button  className="quantity-minus d-icon-minus"></button>
											<input  className="quantity form-control" type="number" min="1" max="1000000"/>
											<button  className="quantity-plus d-icon-plus"></button>
										</div>
										<button
											 className="btn-product btn-cart text-normal ls-normal font-weight-semi-bold"><i
												 className="d-icon-bag"></i>Add to
											Cart</button>
									</div>
								</div>

								<hr  className="product-divider mb-3"/>

								<div  className="product-footer">
									<div  className="social-links mr-4">
										<a href="#"  className="social-link social-facebook fab fa-facebook-f"></a>
										<a href="#"  className="social-link social-twitter fab fa-twitter"></a>
										<a href="#"  className="social-link social-pinterest fab fa-pinterest-p"></a>
									</div>
									<span  className="divider d-lg-show"></span>
									<div  className="product-action">
										<a href="#"  className="btn-product btn-wishlist mr-6"><i
												 className="d-icon-heart"></i>Add to
											wishlist</a>
										<a href="#"  className="btn-product btn-compare"><i  className="d-icon-compare"></i>Add to
											compare</a>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div  className="tab tab-nav-simple product-tabs mb-4">
						<ul  className="nav nav-tabs justify-content-center" role="tablist">
							<li  className="nav-item">
								<a  className="nav-link active" href="#product-tab-description">Description</a>
							</li>
							<li  className="nav-item">
								<a  className="nav-link" href="#product-tab-additional">Additional information</a>
							</li>
							<li  className="nav-item">
								<a  className="nav-link" href="#product-tab-shipping-returns">Shipping &amp; Returns</a>
							</li>
							<li  className="nav-item">
								<a  className="nav-link" href="#product-tab-reviews">Reviews (1)</a>
							</li>
						</ul>
						<div  className="tab-content">
							<div  className="tab-pane active in mb-3" id="product-tab-description">
								<div  className="row mt-6">
									<div  className="col-md-6">
										<h5  className="description-title mb-4 font-weight-semi-bold ls-m">Features</h5>
										<p  className="mb-2">
											Praesent id enim sit amet.Tdio vulputate eleifend in in tortor.
											ellus massa. siti iMassa ristique sit amet condim vel, facilisis
											quimequistiqutiqu amet condim Dilisis Facilisis quis sapien. Praesent id
											enim sit amet.
										</p>
										<ul  className="mb-8">
											<li>Praesent id enim sit amet.Tdio vulputate</li>
											<li>Eleifend in in tortor. ellus massa.Dristique sitii</li>
											<li>Massa ristique sit amet condim vel</li>
											<li>Dilisis Facilisis quis sapien. Praesent id enim sit amet</li>
										</ul>
										<h5  className="description-title mb-3 font-weight-semi-bold ls-m">Specifications
										</h5>
										<table  className="table">
											<tbody>
												<tr>
													<th  className="font-weight-semi-bold text-dark pl-0">Material</th>
													<td  className="pl-4">Praesent id enim sit amet.Tdio</td>
												</tr>
												<tr>
													<th  className="font-weight-semi-bold text-dark pl-0">Claimed Size</th>
													<td  className="pl-4">Praesent id enim sit</td>
												</tr>
												<tr>
													<th  className="font-weight-semi-bold text-dark pl-0">Recommended Use
													</th>
													<td  className="pl-4">Praesent id enim sit amet.Tdio vulputate eleifend
														in in tortor. ellus massa. siti</td>
												</tr>
												<tr>
													<th  className="font-weight-semi-bold text-dark border-no pl-0">
														Manufacturer</th>
													<td  className="border-no pl-4">Praesent id enim</td>
												</tr>
											</tbody>
										</table>
									</div>
									<div  className="col-md-6 pl-md-6 pt-4 pt-md-0">
										<h5  className="description-title font-weight-semi-bold ls-m mb-5">Video Description
										</h5>
										<figure  className="p-relative d-inline-block mb-2">
											<img src={require("../../../assets/images/product/product.jpg")} width="559" height="370" alt="Product"
												 className="w-100" style={{backgroundColor: "#f2f3f5"}} />
											<a  className="btn-play btn-iframe" href="video/memory-of-a-woman.mp4">
												<i  className="d-icon-play-solid"></i>
											</a>
										</figure>
										<div  className="icon-box-wrap d-flex flex-wrap">
											<div  className="icon-box icon-box-side icon-border pt-2 pb-2 mb-4 mr-10">
												<div  className="icon-box-icon">
													<i  className="d-icon-lock"></i>
												</div>
												<div  className="icon-box-content">
													<h4  className="icon-box-title lh-1 pt-1 ls-s text-normal">2 year
														warranty</h4>
													<p>Guarantee with no doubt</p>
												</div>
											</div>
											<div  className="divider d-xl-show mr-10"></div>
											<div  className="icon-box icon-box-side icon-border pt-2 pb-2 mb-4">
												<div  className="icon-box-icon">
													<i  className="d-icon-truck"></i>
												</div>
												<div  className="icon-box-content">
													<h4  className="icon-box-title lh-1 pt-1 ls-s text-normal">Free shipping
													</h4>
													<p>On orders over $50.00</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div  className="tab-pane" id="product-tab-additional">
								<ul  className="list-none">
									<li><label>Color:</label>
										<p>Black, Blue, Green</p>
									</li>
									<li><label>Size:</label>
										<p>Ectra Large, Large, Medium</p>
									</li>
								</ul>
							</div>
							<div  className="tab-pane " id="product-tab-shipping-returns">
								<h6  className="mb-2">Free Shipping</h6>
								<p  className="mb-0">We deliver to over 100 countries around the world. For full details of
									the delivery options we offer, please view our <a href="#"
										 className="text-primary">Delivery
										information</a> We hope you’ll love every
									purchase, but if you ever need to return an item you can do so within a month of
									receipt. For full details of how to make a return, please view our <a href="#"
										 className="text-primary">Returns information</a></p>
							</div>
							<div  className="tab-pane " id="product-tab-reviews">
								<div  className="row">
									<div  className="col-lg-4 mb-6">
										<div  className="avg-rating-container">
											<mark>5.0</mark>
											<div  className="avg-rating">
												<span  className="avg-rating-title">Average Rating</span>
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "100%"}}></span>
														<span  className="tooltiptext tooltip-top"></span>
													</div>
													<span  className="rating-reviews">(2 Reviews)</span>
												</div>
											</div>
										</div>
										<div  className="ratings-list mb-2">
											<div  className="ratings-item">
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "100%"}}></span>
														<span  className="tooltiptext tooltip-top"></span>
													</div>
												</div>
												<div  className="rating-percent">
													<span style={{width: "100%"}}></span>
												</div>
												<div  className="progress-value">100%</div>
											</div>
											<div  className="ratings-item">
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "80%"}}></span>
														<span  className="tooltiptext tooltip-top">4.00</span>
													</div>
												</div>
												<div  className="rating-percent">
													<span style={{width: "4%"}}></span>
												</div>
												<div  className="progress-value">0%</div>
											</div>
											<div  className="ratings-item">
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "60%"}}></span>
														<span  className="tooltiptext tooltip-top">4.00</span>
													</div>
												</div>
												<div  className="rating-percent">
													<span style={{width: "0%"}}></span>
												</div>
												<div  className="progress-value">0%</div>
											</div>
											<div  className="ratings-item">
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "40%"}}></span>
														<span  className="tooltiptext tooltip-top">4.00</span>
													</div>
												</div>
												<div  className="rating-percent">
													<span style={{width: "0%"}}></span>
												</div>
												<div  className="progress-value">0%</div>
											</div>
											<div  className="ratings-item">
												<div  className="ratings-container mb-0">
													<div  className="ratings-full">
														<span  className="ratings" style={{width: "20%"}}></span>
														<span  className="tooltiptext tooltip-top">4.00</span>
													</div>
												</div>
												<div  className="rating-percent">
													<span style={{width: "0%"}}></span>
												</div>
												<div  className="progress-value">0%</div>
											</div>
										</div>
										<a  className="btn btn-dark btn-rounded submit-review-toggle" href="#">Submit
											Review</a>
									</div>
									<div  className="col-lg-8 comments pt-2 pb-10 border-no">
										<nav  className="toolbox">
											<div  className="toolbox-left">
												<div  className="toolbox-item">
													<a href="#"  className="btn btn-outline btn-rounded">All Reviews</a>
												</div>
												<div  className="toolbox-item">
													<a href="#"  className="btn btn-outline btn-rounded">With Images</a>
												</div>
												<div  className="toolbox-item">
													<a href="#"  className="btn btn-outline btn-rounded">With Videos</a>
												</div>
											</div>
											<div  className="toolbox-right">
												<div  className="toolbox-item toolbox-sort select-box text-dark">
													<label>Sort By :</label>
													<select name="orderby"  className="form-control">
														<option value="">Default Order</option>
														<option value="newest" selected>Newest Reviews
														</option>
														<option value="oldest">Oldest Reviews</option>
														<option value="high_rate">Highest Rating</option>
														<option value="low_rate">Lowest Rating</option>
														<option value="most_likely">Most Likely</option>
														<option value="most_unlikely">Most Unlikely</option>
													</select>
												</div>
											</div>
										</nav>
										<ul  className="comments-list">
											<li>
												<div  className="comment">
													<figure  className="comment-media">
														<a href="#">
															<img src={require("../../../assets/images/blog/comments/1.jpg")} alt="avatar"/>
														</a>
													</figure>
													<div  className="comment-body">
														<div  className="comment-rating ratings-container">
															<div  className="ratings-full">
																<span  className="ratings" style={{width: "100%"}}></span>
																<span  className="tooltiptext tooltip-top"></span>
															</div>
														</div>
														<div  className="comment-user">
															<span  className="comment-date">by <span
																	 className="font-weight-semi-bold text-uppercase text-dark">John
																	Doe</span> on
																<span  className="font-weight-semi-bold text-dark">Nov 22,
																	2018</span></span>
														</div>

														<div  className="comment-content mb-5">
															<p>Sed pretium, ligula sollicitudin laoreet viverra, tortor
																libero sodales leo,
																eget blandit nunc tortor eu nibh. Nullam mollis. Ut
																justo.
																Suspendisse potenti.
																Sed egestas, ante et vulputate volutpat, eros pede
																semper
																est, vitae luctus metus libero eu augue.</p>
														</div>
														<div  className="file-input-wrappers">
															<img  className="btn-play btn-img pwsp"
																src={require("../../../assets/images/products/product1.jpg")} width="280"
																height="315" alt="product" />


															<img  className="btn-play btn-img pwsp"
																src={require("../../../assets/images/products/product3.jpg")} width="280"
																height="315" alt="product" />

															<a  className="btn-play btn-iframe"
																
																href="video/memory-of-a-woman.mp4">
																<i  className="d-icon-play-solid"></i>
															</a>
														</div>
														<div  className="feeling mt-5">
															<button
																 className="btn btn-link btn-icon-left btn-slide-up btn-infinite like mr-2">
																<i  className="fa fa-thumbs-up"></i>
																Like (<span  className="count">0</span>)
															</button>
															<button
																 className="btn btn-link btn-icon-left btn-slide-down btn-infinite unlike">
																<i  className="fa fa-thumbs-down"></i>
																Unlike (<span  className="count">0</span>)
															</button>
														</div>
													</div>
												</div>
											</li>
											<li>
												<div  className="comment">
													<figure  className="comment-media">
														<a href="#">
															<img src={require("../../../assets/images/blog/comments/2.jpg")} alt="avatar"/>
														</a>
													</figure>

													<div  className="comment-body">
														<div  className="comment-rating ratings-container">
															<div  className="ratings-full">
																<span  className="ratings"style={{width: "100%"}}></span>
																<span  className="tooltiptext tooltip-top"></span>
															</div>
														</div>
														<div  className="comment-user">
															<span  className="comment-date">by <span
																	 className="font-weight-semi-bold text-uppercase text-dark">John
																	Doe</span> on
																<span  className="font-weight-semi-bold text-dark">Nov 22,
																	2018</span></span>
														</div>

														<div  className="comment-content">
															<p>Sed pretium, ligula sollicitudin laoreet viverra, tortor
																libero sodales leo, eget blandit nunc tortor eu nibh.
																Nullam
																mollis.
																Ut justo. Suspendisse potenti. Sed egestas, ante et
																vulputate volutpat,
																eros pede semper est, vitae luctus metus libero eu
																augue.
																Morbi purus libero,
																faucibus adipiscing, commodo quis, avida id, est. Sed
																lectus. Praesent elementum
																hendrerit tortor. Sed semper lorem at felis. Vestibulum
																volutpat.</p>
														</div>
														<div  className="feeling mt-5">
															<button
																 className="btn btn-link btn-icon-left btn-slide-up btn-infinite like mr-2">
																<i  className="fa fa-thumbs-up"></i>
																Like (<span  className="count">0</span>)
															</button>
															<button
																 className="btn btn-link btn-icon-left btn-slide-down btn-infinite unlike">
																<i  className="fa fa-thumbs-down"></i>
																Unlike (<span  className="count">0</span>)
															</button>
														</div>
													</div>

												</div>
											</li>
										</ul>
										<nav  className="toolbox toolbox-pagination justify-content-end">
											<ul  className="pagination">
												<li  className="page-item disabled">
													<a  className="page-link page-link-prev" href="#" aria-label="Previous"
														tabIndex={-1} aria-disabled="true">
														<i  className="d-icon-arrow-left"></i>Prev
													</a>
												</li>
												<li  className="page-item active" aria-current="page"><a  className="page-link"
														href="#">1</a>
												</li>
												<li  className="page-item"><a  className="page-link" href="#">2</a></li>
												<li  className="page-item"><a  className="page-link" href="#">3</a></li>
												<li  className="page-item page-item-dots"><a  className="page-link" href="#">6</a>
												</li>
												<li  className="page-item">
													<a  className="page-link page-link-next" href="#" aria-label="Next">
														Next<i  className="d-icon-arrow-right"></i>
													</a>
												</li>
											</ul>
										</nav>
									</div>
								</div>
								{/* <!-- End Comments --> */}
								<div  className="review-form-section">
									<div  className="review-overlay"></div>
									<div  className="review-form-wrapper">
										<div  className="title-wrapper text-left">
											<h3  className="title title-simple text-left text-normal">Add a Review</h3>
											<p>Your email address will not be published. Required fields are marked *
											</p>
										</div>
										<div  className="rating-form">
											<label htmlFor="rating"  className="text-dark">Your rating * </label>
											<span  className="rating-stars selected">
												<a  className="star-1" href="#">1</a>
												<a  className="star-2" href="#">2</a>
												<a  className="star-3" href="#">3</a>
												<a  className="star-4 active" href="#">4</a>
												<a  className="star-5" href="#">5</a>
											</span>

											<select name="rating" id="rating" required   style={{display: "none;"}}>
												<option value="">Rate…</option>
												<option value="5">Perfect</option>
												<option value="4">Good</option>
												<option value="3">Average</option>
												<option value="2">Not that bad</option>
												<option value="1">Very poor</option>
											</select>
										</div>
										<form action="#">
											<textarea id="reply-message" cols={30} rows={6}  className="form-control mb-4"
												placeholder="Comment *" required></textarea>

											<div  className="review-medias">
												<div  className="file-input form-control image-input"
													style={{backgroundImage: "url(images/product/placeholder.png);"}}>
													<div  className="file-input-wrapper">
													</div>
													<label  className="btn-action btn-upload" title="Upload Media">
														<input type="file" accept=".png, .jpg, .jpeg"
															name="riode_comment_medias_image_1"/>
													</label>
													<label  className="btn-action btn-remove" title="Remove Media">
													</label>
												</div>
												<div  className="file-input form-control image-input"
													style={{backgroundImage: "url(images/product/placeholder.png);"}}>
													<div  className="file-input-wrapper">
													</div>
													<label  className=" btn-action btn-upload" title="Upload Media">
														<input type="file" accept=".png, .jpg, .jpeg"
															name="riode_comment_medias_image_2"/>
													</label>
													<label  className="btn-action btn-remove" title="Remove Media">
													</label>
												</div>
												<div  className="file-input form-control video-input"
													style={{backgroundImage: "url(images/product/placeholder.png);"}}>
													<video  className="file-input-wrapper" controls></video>
													<label  className="btn-action btn-upload" title="Upload Media">
														<input type="file" accept=".avi, .mp4"
															name="riode_comment_medias_video_1"/>
													</label>
													<label  className="btn-action btn-remove" title="Remove Media">
													</label>
												</div>
											</div>
											<p>Upload images and videos. Maximum count: 3, size: 2MB</p>
											<button type="submit"  className="btn btn-primary btn-rounded">Submit<i
													 className="d-icon-arrow-right"></i></button>
										</form>
									</div>
								</div>
								{/* <!-- End Reply --> */}
							</div>
						</div>
					</div>

					<section  className="pt-3 mt-10">
						<h2  className="title title-center ls-normal">Related Products</h2>

						<div  className="owl-carousel owl-theme owl-nav-full row cols-2 cols-md-3 cols-lg-4"
							data-owl-options="{
							'items': 5,
							'nav': false,
							'loop': false,
							'dots': true,
							'margin': 20,
							'responsive': {
								'0': {
									'items': 2
								},
								'768': {
									'items': 3
								},
								'992': {
									'items': 4,
									'dots': false,
									'nav': true
								}
							}
						}">
							<div  className="product">
								<figure  className="product-media">
									<a href="demo2-product.html">
										<img src={require("../../../assets/images/demos/demo2/product/featured/1.jpg")} alt="product" width="280"
											height="315" style={{backgroundColor: "#f2f3f5"}} />
									</a>
									<div  className="product-label-group">
										<label  className="product-label label-new">new</label>
									</div>
									<div  className="product-action-vertical">
										<a href="#"  className="btn-product-icon btn-cart" data-toggle="modal"
											data-target="#addCartModal" title="Add to cart"><i
												 className="d-icon-bag"></i></a>
										<a href="#"  className="btn-product-icon btn-wishlist" title="Add to wishlist"><i
												 className="d-icon-heart"></i></a>
									</div>
									<div  className="product-action">
										<a href="#"  className="btn-product btn-quickview" title="Quick View">Quick View</a>
									</div>
								</figure>
								<div  className="product-details">
									<div  className="product-cat">
										<a href="demo2-shop.html">Clothing</a>
									</div>
									<h3  className="product-name">
										<a href="demo2-product.html">Women's Fashion T Shirt</a>
									</h3>
									<div  className="product-price">
										<ins  className="new-price">$199.00</ins><del  className="old-price">$210.00</del>
									</div>
									<div  className="ratings-container">
										<div  className="ratings-full">
											<span  className="ratings" style={{width:"100%"}}></span>
											<span  className="tooltiptext tooltip-top"></span>
										</div>
										<a href="demo2-product.html"  className="rating-reviews">( <span
												 className="review-count">6</span> reviews
											)</a>
									</div>
								</div>
							</div>
							<div  className="product">
								<figure  className="product-media">
									<a href="demo2-product.html">
										<img src={require("../../../assets/images/demos/demo2/product/featured/2.jpg")} alt="product" width="280"
											height="315" style={{backgroundColor: "#f2f3f5"}} />
									</a>
									<div  className="product-action-vertical">
										<a href="#"  className="btn-product-icon btn-cart" data-toggle="modal"
											data-target="#addCartModal" title="Add to cart"><i
												 className="d-icon-bag"></i></a>
										<a href="#"  className="btn-product-icon btn-wishlist" title="Add to wishlist"><i
												 className="d-icon-heart"></i></a>
									</div>
									<div  className="product-action">
										<a href="#"  className="btn-product btn-quickview" title="Quick View">Quick View</a>
									</div>
								</figure>
								<div  className="product-details">
									<div  className="product-cat">
										<a href="demo2-shop.html">Clothing</a>
									</div>
									<h3  className="product-name">
										<a href="demo2-product.html">Men's Summer Clothing</a>
									</h3>
									<div  className="product-price">
										<span  className="price">$35.00</span>
									</div>
									<div  className="ratings-container">
										<div  className="ratings-full">
											<span  className="ratings" style={{width:"100%"}}></span>
											<span  className="tooltiptext tooltip-top"></span>
										</div>
										<a href="demo2-product.html"  className="rating-reviews">( <span
												 className="review-count">6</span> reviews
											)</a>
									</div>
								</div>
							</div>
							<div  className="product">
								<figure  className="product-media">
									<a href="demo2-product.html">
										<img src={require("../../../assets/images/demos/demo2/product/featured/3.jpg")} alt="product" width="280"
											height="315" style={{backgroundColor: "#f2f3f5"}} />
									</a>

									<div  className="product-label-group">
										<label  className="product-label label-sale">27% off</label>
									</div>
									<div  className="product-action-vertical">
										<a href="#"  className="btn-product-icon btn-cart" data-toggle="modal"
											data-target="#addCartModal" title="Add to cart"><i
												 className="d-icon-bag"></i></a>
										<a href="#"  className="btn-product-icon btn-wishlist" title="Add to wishlist"><i
												 className="d-icon-heart"></i></a>
									</div>
									<div  className="product-action">
										<a href="#"  className="btn-product btn-quickview" title="Quick View">Quick View</a>
									</div>
								</figure>
								<div  className="product-details">
									<div  className="product-cat">
										<a href="demo2-shop.html">Clothing</a>
									</div>
									<h3  className="product-name">
										<a href="demo2-product.html">Black Fashion T Shirt</a>
									</h3>
									<div  className="product-price">
										<span  className="price">$19.00</span>
									</div>
									<div  className="ratings-container">
										<div  className="ratings-full">
											<span  className="ratings" style={{width:"100%"}}></span>
											<span  className="tooltiptext tooltip-top"></span>
										</div>
										<a href="demo2-product.html"  className="rating-reviews">( <span
												 className="review-count">6</span> reviews
											)</a>
									</div>
								</div>
							</div>
							<div  className="product">
								<figure  className="product-media">
									<a href="demo2-product.html">
										<img src={require("../../../assets/images/demos/demo2/product/featured/4.jpg")} alt="product" width="280"
											height="315" style={{backgroundColor: "#f2f3f5"}} />
									</a>
									<div  className="product-action-vertical">
										<a href="#"  className="btn-product-icon btn-cart" data-toggle="modal"
											data-target="#addCartModal" title="Add to cart"><i
												 className="d-icon-bag"></i></a>
										<a href="#"  className="btn-product-icon btn-wishlist" title="Add to wishlist"><i
												 className="d-icon-heart"></i></a>
									</div>
									<div  className="product-action">
										<a href="#"  className="btn-product btn-quickview" title="Quick View">Quick View</a>
									</div>
								</figure>
								<div  className="product-details">
									<div  className="product-cat">
										<a href="demo2-shop.html">Clothing</a>
									</div>
									<h3  className="product-name">
										<a href="demo2-product.html">Men's Fashion T Shirt</a>
									</h3>
									<div  className="product-price">
										<ins  className="new-price">$98.00</ins><del  className="old-price">$210.00</del>
									</div>
									<div  className="ratings-container">
										<div  className="ratings-full">
											<span  className="ratings" style={{width:"100%"}}></span>
											<span  className="tooltiptext tooltip-top"></span>
										</div>
										<a href="demo2-product.html"  className="rating-reviews">( <span
												 className="review-count">6</span> reviews
											)</a>
									</div>
								</div>
							</div>
						</div>
					</section>
				</div>
			</div>

		</main>
    
        
        
        
        
        
        
        
       
        
        </>

        );
    }
}

function mapStateToProps(state: any) { return {}; }

function mapDispatchToProps(dispatch: Function) { return {
    GetAllUsers: (payload: any, callback: Function) =>
            dispatch(GetAllUsers(payload, callback)),
            GetPromo: (payload: any, callback: Function) => dispatch(GetPromo(payload, callback)),
    // getUserProfileData: (payload: any,token:any, callback: Function) => dispatch(getUserProfileData(payload,token, callback))
} }

export const ProductViewModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(ProductViewScreen)
);

export const ProductView = AppLayout(ProductViewModule);