import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/applayout";
import { withTranslation } from "react-i18next";
import Utils from "../../../utils";
import { doLogin } from "../../auth";
import { Count } from "../home.action";
// import {getUserProfileData} from "../../edit-actor-profile/edit-actor-profile.action";

interface Props {
    t?: any;
    history?: any;
    Count: Function;
    // getUserProfileData: Function;
}

interface State {

    AccountApprovalRequestsCount: string;
    CelebrityCount: string;
    FansCount: string;
    OrderApprovalRequestCount: string;
    AdminProfilesCount: string;
    RecommendationCount: string;
    PopularCount: string;


}
export class HomeScreen extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            AccountApprovalRequestsCount: "",
            CelebrityCount: "",
            FansCount: "",
            OrderApprovalRequestCount: "",
            AdminProfilesCount: "",
            RecommendationCount: "",
            PopularCount: "",

        };
    }

    count = async () => {
        console.log('All Counts Actors are here')
        this.props.Count({}, (response: any) => {
            console.log('back')
            console.log(response)
            this.setState({ AccountApprovalRequestsCount: response.Account_Approval_Request, CelebrityCount: response.Celebrities, FansCount: response.Fans, OrderApprovalRequestCount: response.Order_Approval_Request, AdminProfilesCount: response.Admin_Profile, PopularCount: response.Popular, RecommendationCount: response.Recommmend }, () => {

                //@ts-ignore
                //  window.SlikSlider();


            });
        });

    };

    // getAllActors = async (status: string) => {
    //     console.log('All Actors are here')
    //     this.props.GetAllActors({}, (response: any) => {
    //         console.log('back')
    //         console.log(response)
    //         this.setState({ actorsList: response }, ()=>{

    //             //@ts-ignore
    //             window.SlikSlider();


    //         });
    //     });

    // };


    async componentDidMount() {
        const userToken = localStorage.getItem("accessToken");

        // if (userToken) {
        //     console.log('sending request')
        // }
        //     else {

        //         window.location.href = "/";
        //     }

        // // this.getAllActors('Seller')
        //     this.count()
        // const userToken = await localStorage.getItem("accessToken");

    }

    render() {
        return (
           <>
               {/* <!--=================================
    banner --> */}
    <section className="slider-01 banner-03 bg-secondary position-relative">
      <div id="main-slider" className="swiper-container">
        <div className="swiper-wrapper">
          <div className="swiper-slide align-items-center d-flex space-pt">
            <img className="img-fluid position-absolute banner-round-bg" src="images/svg/18.svg" alt="" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.1s"/>
            <div className="swipeinner container position-relative">
              <div className="row">
                <div className="col-12 col-xl-7 col-lg-8 col-md-10">
                  <div className="banner-content position-relative">
                    <div className="banner-img1 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.3s"><img className="img-fluid" src="images/svg/14.svg" alt=""/></div>
                    <div className="banner-img2 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.6s"><img className="img-fluid" src="images/svg/15.svg" alt=""/></div>
                    <div className="banner-img3 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.9s"><img className="img-fluid" src="images/svg/16.svg" alt=""/></div>
                    <div className="banner-img4 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="3.1s"><img className="img-fluid" src="images/svg/17.svg" alt=""/></div>
                    <h1 className="text-white" data-swiper-animation="fadeInLeft" data-duration="1s" data-delay="1s">Superior solutions that help you to shine.</h1>
                    <p className="mb-5 text-white" data-swiper-animation="fadeInLeft" data-duration="1s" data-delay="1.7s">You can focus on your practice and your business. </p>
                    <a className="btn btn-primary" href="#appointment" data-swiper-animation="fadeInLeft" data-duration="1s" data-delay="2.2s">Make Appointment</a>
                  </div>
                </div>
                <div className="col-12 col-xl-5 col-lg-4">
                  <img className="position-absolute banner-bg2 d-none d-lg-block" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.6s" src="images/bg/03.png" alt=""/>
                </div>
              </div>
            </div>
          </div>
          <div className="swiper-slide slide-02 align-items-center d-flex space-pt">
            <img className="img-fluid position-absolute banner-round-bg" src="images/svg/20.svg" alt="" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.1s"/>
            <div className="swipeinner container position-relative">
              <div className="row">
                <div className="col-12 col-xl-5 col-lg-4 ">
                  <img className="position-absolute banner-bg2 d-none d-lg-block" data-swiper-animation="fadeInUp" data-duration="1s" data-delay="0.6s" src="images/bg/02.png" alt=""/>
                </div>
                <div className="col-12 col-xl-7 col-lg-8 col-md-10">
                  <div className="banner-content position-relative text-end">
                    <div className="banner-img1 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.3s"><img className="img-fluid" src="images/svg/14.svg" alt=""/></div>
                    <div className="banner-img2 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.6s"><img className="img-fluid" src="images/svg/15.svg" alt=""/></div>
                    <div className="banner-img3 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="2.9s"><img className="img-fluid" src="images/svg/16.svg" alt=""/></div>
                    <div className="banner-img4 position-absolute" data-swiper-animation="bounceIn" data-duration="1s" data-delay="3.2s"><img className="img-fluid" src="images/svg/17.svg" alt=""/></div>
                    <h1 className="text-white" data-swiper-animation="fadeInRight" data-duration="1s" data-delay="1s">Best outcome for every patient every time.</h1>
                    <p className="mb-5 text-white" data-swiper-animation="fadeInRight" data-duration="1s" data-delay="1.7s">Achieving better health care one patient at a time. </p>
                    <a className="btn btn-primary" href="#dep" data-swiper-animation="fadeInRight" data-duration="1s" data-delay="2.2s">Discover more</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Pagination --> */}
      <div className="swiper-button-prev" tabIndex={0} role="button" aria-label="Previous slide"><i className="fas fa-chevron-left"></i>
      </div>
      <div className="swiper-button-next" tabIndex={0} role="button" aria-label="Next slide"><i className="fas fa-chevron-right"></i></div>
    </section>
    {/* <!--=================================
    banner --> */}

        {/* <!--=================================
    About --> */}
    <section className="space-ptb position-relative">
      <div className="container">
        <div className="row">
          <div className="col-xl-8 col-lg-12 col-md-12">
            <div className="section-title left-divider">
              <span>About our company</span>
              <h2>Contributing to Society through Healthcare.</h2>
              <div className="row">
                <div className="col-lg-6 mb-4 mb-lg-0">
                  <h6 className="mb-0">Let us do the billing so you can focus on the healing.</h6>
                </div>
                <div className="col-lg-6">
                  <p className="mb-0">The first thing to remember about success is that it is a process – nothing more, nothing less.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 mb-4 mb-lg-0">
                <div className="position-relative bg-overlay-black-50">
                  <img className="w-100" src="images/about/06.jpg" alt=""/>
                  <div className="popup-video">
                    <a className="video-btn popup-icon popup-youtube" href="https://www.youtube.com/watch?v=aBoaCHKJKd8"><i className="fas fa-play fa-1x"></i></a>
                  </div>
                </div>
              </div>
              <div className="col-lg-6">
                <p className="mb-0">We are an Experienced Revenue Cycle Management team providing Peace of Mind and Confidence to our clients. Outsourcing your financial reimbursements to our team of CERTIFIED AAPC management, allows you to focus on your Practice and Patients. Investments are made with the returns or RESULTS in mind. Allow us to bring you the results you and your office works for day in and day out. Medical Billing Service done right. Your billing should relieve your office stress and increase your revenue at the same time.</p>
                {/* <ul className="list-unstyled">
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                  <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Success is something of which we all want more.</li>
                </ul> */}
                <div className="pt-4 pt-md-5">
                  <img className="img-fluid me-3" src="images/sign.png" alt=""/>
                  <div className="author-text">
                    <h5 className="text-primary">Alice Williams</h5>
                    <p className="mb-0 text-secondary">Head of department</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 pt-4 pt-md-5">
                <p className="mb-0">Our main objective is to provide services that brings your investment in us a return worth your time. A National Article on "MEDPAGE TODAY" recently recorded practices in the US are only receiving on average 70% of Account Receivables from their in or out of house medical billing services. When you have a certified agency with expertise handling your billing, you can rest assure your investment in our team will increase your revenue while acquiring our services from 10% to 20%. INCREASE your BOTTOM LINE.Your Practice deserves to see a return in its day to day operations</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 position-relative">
            <div className="stethoscope-img d-none d-xl-block mt-6">
              <img className="img-fluid" src="images/stethoscope.png" alt=""/>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    About -->



    <!--=================================
    Departments --> */}
    <section id="dep">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-10 text-center">
            <div className="section-title center-divider">
              <span className="">Our Services</span>
              <h2 className="mb-0">We Believe The Heart Of Healthcare Is Service To Others</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="space-pb overflow-hidden">
      <div className="container">
        <div className="row">
          <div className="col-12">
            {/* <!-- Tab-nav START --> */}
            <ul className="nav nav-tabs nav-tabs-02 justify-content-center" id="myTab" role="tablist">
              <li className="nav-item">
                <a className="nav-link active" id="department01-tab" data-bs-toggle="tab" href="#department01" role="tab" aria-controls="department01" aria-selected="false">Billing Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="departments02-tab" data-bs-toggle="tab" href="#departments02" role="tab" aria-controls="departments02" aria-selected="true">Medical Coding Services</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="departments03-tab" data-bs-toggle="tab" href="#departments03" role="tab" aria-controls="departments03" aria-selected="true">Credentialing</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="departments04-tab" data-bs-toggle="tab" href="#departments04" role="tab" aria-controls="departments04" aria-selected="true">AR Recovery Services</a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" id="departments05-tab" data-bs-toggle="tab" href="#departments05" role="tab" aria-controls="departments05" aria-selected="true">Consulting</a>
              </li> */}
            </ul>
            {/* <!-- Tab-nav END -->

            <!-- Tab-content START --> */}
            <div className="tab-content  space-pt" id="myTabContent">
              {/* <!-- department01 START --> */}
              <div className="tab-pane fade show active" id="department01" role="tabpanel" aria-labelledby="department01-tab">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-lg-0 mb-4">
                    <img className="img-fluid" src="images/departments/10.jpg" alt=""/>
                  </div>
                  <div className="col-lg-6 ps-3 ps-lg-4">
                    <h3 className="mb-4">Superior solutions that help you to shine.</h3>
                    {/* <ul className="list-unstyled pb-4 pb-lg-5 pt-2 mb-0">
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                      <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                    </ul> */}
                    <p className="mb-4 mb-lg-5">Our end-to-end medical billing services provide maximum reimbursement for our clients while reducing overall costs. We don’t just monitor your account we actively manage it.</p>
                    <a className="btn btn-primary" href="/services">View all Services</a>
                  </div>
                </div>
              </div>
              {/* <!-- department01 END --> */}

              {/* <!-- departments02-tab START --> */}
              <div className="tab-pane fade" id="departments02" role="tabpanel" aria-labelledby="departments02-tab">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-lg-0 mb-4">
                    <img className="img-fluid" src="images/departments/11.jpg" alt=""/>
                  </div>
                  <div className="col-lg-6 ps-3 ps-lg-4">
                    <h3 className="mb-4">Remarkable People. Remarkable Medicine.</h3>
                    {/* <ul className="list-unstyled pb-4 pb-lg-5 pt-2 mb-0">
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                      <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                    </ul> */}
                    <p className="mb-4 mb-lg-5">Team of certified medical coders offers customized ICD, CPT and HCPCS coding solutions for any type of medical facility. Contact us for your coding needs!</p>
                    <a className="btn btn-primary" href="/services">View all Services</a>
                  </div>
                </div>
              </div>
              {/* <!-- departments02-tab END --> */}

              {/* <!-- departments03-tab START --> */}
              <div className="tab-pane fade" id="departments03" role="tabpanel" aria-labelledby="departments03-tab">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-lg-3 mb-4">
                    <img className="img-fluid" src="images/departments/12.jpg" alt=""/>
                  </div>
                  <div className="col-lg-6 ps-0 ps-lg-4">
                    <h3 className="mb-4">Knowledge makes all the difference.</h3>
                    {/* <ul className="list-unstyled pb-4 pb-lg-5 pt-2 mb-0">
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                      <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                    </ul> */}
                    <p className="mb-4 mb-lg-5">Let the insurance companies do your marketing for you! As an in-network provider, you’ll get more patients without having to do any advertising. Our team will help you get accredited by more insurance companies</p>
                    <a className="btn btn-primary" href="/services">View all Services</a>
                  </div>
                </div>
              </div>
              {/* <!-- departments03-tab END --> */}

              {/* <!-- departments04-tab START --> */}
              <div className="tab-pane fade" id="departments04" role="tabpanel" aria-labelledby="departments04-tab">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-lg-3 mb-4">
                    <img className="img-fluid" src="images/departments/13.jpg" alt=""/>
                  </div>
                  <div className="col-lg-6 ps-0 ps-lg-4">
                    <h3 className="mb-4">Dedication. Compassion. Innovation.</h3>
                    {/* <ul className="list-unstyled pb-4 pb-lg-5 pt-2 mb-0">
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                      <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                    </ul> */}
                    <p className="mb-4 mb-lg-5">Alpha Medical Billing specializes in Aging A/R recovery. We pursue every old claim by assigning our dedicated team of Aging A/R specialists. If your in-house billing team can’t keep up with Aging, we can help!</p>
                    <a className="btn btn-primary" href="/services">View all Services</a>
                  </div>
                </div>
              </div>
              {/* <!-- departments04-tab END --> */}

              {/* <!-- departments05-tab START --> */}
              <div className="tab-pane fade" id="departments05" role="tabpanel" aria-labelledby="departments05-tab">
                <div className="row align-items-center">
                  <div className="col-lg-6 mb-lg-0 mb-4">
                    <img className="img-fluid" src="images/departments/14.jpg" alt=""/>
                  </div>
                  <div className="col-lg-6 ps-3 ps-lg-4">
                    <h3 className="mb-4">Curing patients and bills in one breath.</h3>
                    {/* <ul className="list-unstyled pb-4 pb-lg-5 pt-2 mb-0">
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                      <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                      <li className="d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                    </ul> */}
                    <p className="mb-4 mb-lg-5">Even if Alpha Medical Billing isn’t your medical billing company, we can help you get the most from your medical billing processes.</p>
                    <a className="btn btn-primary" href="/services">View all Services</a>
                  </div>
                </div>
              </div>
              {/* <!-- departments05-tab END --> */}
            </div>
            {/* <!-- Tab-content END --> */}
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Departments --> */}

{/* <!--=================================
    Service --> */}
    <section className="choose-people bg-primary-half-lg">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-9 col-md-10 text-center position-relative">
            <div className="section-title center-divider mb-4 mb-md-0">
              <span className="text-white">Key Features</span>
              <h2 className="text-white mb-0">We Believe The Heart Of Healthcare Is Service To Others</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="space-pb mt-n5 mt-lg-n7">
      <div className="container">
        <div className="row g-0">
          {/* <div className="col-md-4 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-ambulance"></i>
              </div>
              <div className="feature-content">
                <h6>Fast Ambulance</h6>
                <p className="mb-0">Willpower is a good start, but it’s very difficult to stay on track simply through willpower.</p>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-tooth"></i>
              </div>
              <div className="feature-content">
                <h6>Eligibility Check</h6>
                <p className="mb-0">We confirm every patients’ insurance eligibility to streamline your process, shorten account receivable days, and avoid denials.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-microscope"></i>
              </div>
              <div className="feature-content">
                <h6>Charge Entry</h6>
                <p className="mb-0">Get timely reimbursement as our detailed and error-free charge entry process ensures first-time approved claim submission. Our first time pass rate is over 98%.</p>
              </div>
            </div>
          </div>
          {/* <div className="col-md-4 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-baby-boy"></i>
              </div>
              <div className="feature-content">
                <h6>Children Center</h6>
                <p className="mb-0">Remind yourself of someone you know who died suddenly and the fact that there is no guarantee.</p>
              </div>
            </div>
          </div> */}
          <div className="col-md-6 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-transfusion"></i>
              </div>
              <div className="feature-content">
                <h6>Claim Scrubbing</h6>
                <p className="mb-0">Our top-of-the-line claim scrubbing ensures error-free and clean claims are submitted for faster reimbursement and minimum denials/rejections.</p>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6">
            <div className="feature-items feature-items-style-02">
              <div className="feature-icon">
                <i className="flaticon-liquid-soap"></i>
              </div>
              <div className="feature-content">
                <h6>Electronic Submission</h6>
                <p className="mb-0">Corrections and re-submissions are completed systematically and in a timely manner for any claims that are held to identify and resolve the issue as soon as possible.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Service -->

    {/* <!--=================================
    Counter --> */}
    <section className="space-pt space-lg-pb bg-primary-half-lg">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-sm-6 text-center">
            <div className="counter counter-02 pe-0 mb-lg-0">
              <div className="counter-icon">
                <i className="flaticon-hospital-bed"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="1790" data-speed="10000">1790</span>
                <label>We also know those epic stories, those modern-day legends surrounding.</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 text-center">
            <div className="counter counter-02 pe-0 mb-lg-0">
              <div className="counter-icon">
                <i className="flaticon-electrocardiogram"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="245" data-speed="10000">245</span>
                <label>We can look a bit further back in time to Albert Einstein or even further</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 text-center">
            <div className="counter counter-02 pe-0 mb-sm-0">
              <div className="counter-icon">
                <i className="flaticon-hospital"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="491" data-speed="10000">491</span>
                <label>I truly believe Augustine’s words are true and if you look at history you know it is true.</label>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-sm-6 text-center">
            <div className="counter counter-02 pe-0 mb-0">
              <div className="counter-icon">
                <i className="flaticon-electrocardiogram-1"></i>
              </div>
              <div className="counter-content">
                <span className="timer" data-to="1090" data-speed="10000">1090</span>
                <label>Who realize only a small percentage of their potential. We all know people who live.</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Counter --> */}

    {/* <!--=================================
    Appointment Form --> */}
    <section className="space-pb mt-4 mt-md-5 mt-lg-n6 position-relative" id="appointment">
      <div className="container">
        <div className="appointment-form pb-lg-0 ps-lg-5">
          <div className="row align-items-center pb-lg-0">
            <div className="col-lg-5 mb-4 mb-lg-0 text-center order-lg-2 align-self-end">
              <img className="img-fluid" src="images/about/04.png" alt=""/>
            </div>
            <div className="col-lg-7 order-lg-1">
              <div className="section-title left-divider">
                <span>Need an Expert?</span>
                <h2>Make an appointment now!</h2>
                <p>The sad thing is the majority of people have no clue about what they truly want. They have no clarity.</p>
              </div>
              <form className="pe-lg-5">
                <div className="row align-items-center">
                  <div className="form-group mb-3 col-md-6">
                    <input type="text" className="form-control" id="first-name" placeholder="First Name"/>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <input type="text" className="form-control" id="last-name" placeholder="Last Name"/>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <input type="email" className="form-control" id="email" placeholder="Email"/>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <input type="text" className="form-control" id="phone" placeholder="Phone Number"/>
                  </div>
                  <div className="form-group mb-3 col-lg-12">
                    <input type="text" className="form-control" id="address" placeholder="Address"/>
                  </div>
                  <div className="form-group mb-3 select-border col-md-4 ">
                    <select className="form-control basic-select" id="city">
                      <option value="City">City</option>
                      <option value="New york">New york</option>
                      <option value="Los Angeles">Los angeles</option>
                      <option value="Chicago">Chicago</option>
                      <option value="Assam">Mumbai</option>
                      <option value="Assam">Delhi</option>
                    </select>
                  </div>
                  <div className="form-group mb-3 select-border col-md-4">
                    <select className="form-control basic-select" id="state">
                      <option value="State">State</option>
                      <option value="AL">New york</option>
                      <option value="AK">California</option>
                      <option value="AZ">Illinois</option>
                      <option value="AR">Maharashtra</option>
                      <option value="AR">Delhi</option>
                    </select>
                  </div>
                  <div className="form-group mb-3 col-md-4">
                    <input type="text" className="form-control" id="zip" placeholder="Zip"/>
                  </div>
                  <div className="form-group mb-3 col-md-6 datetimepickers">
                    <label className="text-dark form-label ">Required Date</label>
                    <div className="input-group date" id="datetimepicker-01" data-target-input="nearest">
                      <input type="text" className="form-control datetimepicker-input" placeholder="Date" data-target="#datetimepicker-01"/>
                      <div className="input-group-append d-flex" data-target="#datetimepicker-01" data-toggle="datetimepicker">
                        <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 select-border col-md-6">
                    <label className="text-dark form-label">Select Time</label>
                    <div className="input-group date" id="datetimepicker-03" data-target-input="nearest">
                      <input type="text" className="form-control datetimepicker-input" placeholder="Time" data-target="#datetimepicker-03"/>
                      <div className="input-group-append d-flex" data-target="#datetimepicker-03" data-toggle="datetimepicker">
                        <div className="input-group-text"><i className="far fa-clock"></i></div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group mb-3 col-sm-12">
                    <button type="submit" className="btn btn-secondory d-grid">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Appointment Form --> */}

    {/* <!--=================================
    Blog --> */}
    {/* <section className="space-sm-pb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="section-title center-divider text-center">
              <span>Check out our</span>
              <h2>Latest News & Blogs</h2>
            </div>
          </div>
        </div>
        <div className="row">
          
          <div className="col-lg-4 col-md-6">
            <div className="blog-post blog-post-02">
              <div className="blog-post-image mb-2">
                <img className="img-fluid" src="images/blog/01.jpg" alt=""/>
              </div>
              <div className="blog-post-content py-0">
                <div className="blog-post-details">
                  <div className="blog-post-meta">
                    <div className="blog-post-author">
                      <span> By <a href="#">Alice Williams</a></span>
                    </div>
                    <div className="blog-post-time">
                      <a href="#"><i className="fas fa-tags text-primary"></i>Covid 19 cases</a>
                    </div>
                  </div>
                  <h6 className="blog-post-title"><a href="blog-detail.html">Coronavirus Disease 2020</a></h6>
                </div>
              </div>
            </div>
          </div>
         
          <div className="col-lg-4 col-md-6">
            <div className="blog-post blog-post-02">
              <div className="blog-post-image mb-2">
                <img className="img-fluid" src="images/blog/02.jpg" alt=""/>
              </div>
              <div className="blog-post-content py-0">
                <div className="blog-post-details">
                  <div className="blog-post-meta">
                    <div className="blog-post-author">
                      <span> By <a href="#">Mellissa Doe</a></span>
                    </div>
                    <div className="blog-post-time">
                      <a href="#"><i className="fas fa-tags text-primary"></i>Cancer cases</a>
                    </div>
                  </div>
                  <h6 className="blog-post-title"><a href="blog-detail.html">Six Information Signs Of Cancer</a></h6>
                </div>
              </div>
            </div>
          </div>
         
          <div className="col-lg-4 col-md-6">
            <div className="blog-post blog-post-02">
              <div className="blog-post-image mb-2">
                <img className="img-fluid" src="images/blog/03.jpg" alt=""/>
              </div>
              <div className="blog-post-content py-0">
                <div className="blog-post-details">
                  <div className="blog-post-meta">
                    <div className="blog-post-author">
                      <span> By <a href="#">Paul Flavius</a></span>
                    </div>
                    <div className="blog-post-time">
                      <a href="#"> <i className="fas fa-tags text-primary"></i>Youth cases</a>
                    </div>
                  </div>
                  <h6 className="blog-post-title"><a href="blog-detail.html">The Loveliness Of The Youth</a></h6>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </section> */}
    {/* <!--=================================
    Blog --> */}

    {/* <!--=================================
    Testimonial --> */}
    {/* <section className="space-pb">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-12">
            
            <div className="owl-carousel testimonial" data-nav-arrow="false" data-items="1" data-md-items="1" data-sm-items="1" data-xs-items="1" data-xx-items="1" data-space="0" data-autoheight="true" style={{backgroundImage: `url(images/pattern.png)`}}>

             
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/09.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   I have gotten at least 50 times the value from Medileaf. I will let my mum know about this, she could really make use of Medileaf! Wow what great service, I love it! Medileaf is the real deal!
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Alice Williams</h6>
                      <span>- Mother.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/04.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   We were treated like royalty. Needless to say we are extremely satisfied with the results. Thank you for making it painless, pleasant and most of all hassle free! It fits our needs perfectly.
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Felica Queen</h6>
                      <span>- Cancer client.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/03.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   We've seen amazing results already. Since I invested in Medileaf I made over 100,000 dollars profits. It's the perfect solution for our business. I was amazed at the quality of Medileaf.
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Paul Flavius</h6>
                      <span>- Heather.</span>
                    </div>
                  </div>
                </div>
              </div>
              
              <div className="item">
                <div className="testimonial-item">
                  <div className="testimonial-avatar">
                    <img className="img-fluid rounded-circle" src="images/avatar/05.jpg" alt=""/>
                  </div>
                  <div className="testimonial-content">
                   I love Medileaf. I have gotten at least 50 times the value from Medileaf. I STRONGLY recommend Medileaf to EVERYONE interested in running a successful online business!
                  </div>
                  <div className="testimonial-author">
                    <div className="testimonial-name">
                      <div className="testimonial-quote">
                        <i className="flaticon-left-quote"></i>
                      </div>
                      <h6 className="mb-1">Harry Russell</h6>
                      <span>- Surgery client.</span>
                    </div>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section> */}
    {/* <!--=================================
    Testimonial --> */}
           </>
        );
    }
}

function mapStateToProps(state: any) {
    return {
        loginResponce: state.authReducer,
    };
}

function mapDispatchToProps(dispatch: Function) {
    return {
        // GetAllActors: (payload: any, callback: Function) =>
        //     dispatch(GetAllActors(payload, callback)),
        doLogin: (payload: any, callback: Function) =>
            dispatch(doLogin(payload, callback)),
        Count: (payload: any, callback: Function) =>
            dispatch(Count(payload, callback)),
    }
}

export const HomeModule = withTranslation()(
    connect(mapStateToProps, mapDispatchToProps)(HomeScreen)
);
//AppLayout(HomeModule)
export const Home = AppLayout(HomeModule);
