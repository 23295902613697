import React, { useEffect } from "react";
// import { BrowserRouter, Redirect, Route, Switch, useLocation } from "react-router-dom";
import { BrowserRouter, useLocation,Routes, Route } from "react-router-dom";
import {
  Login,
  // Forgot,
//  Reset,
 // Signup,
  ResetSuccess,
 // Unsubscribe,
} from "../modules/auth";
import { Home } from "../modules/home/screen";
import { Reset, Signin ,Signup } from "../modules/auth/screen";
import { ContactUs } from "../modules/contactus";
import { AboutUs } from "../modules/aboutus";
import { Services } from "../modules/services";
// import {Register} from "../modules/auth/screen"

import { SubscribedProfile } from "../modules/fan-profile/screen";
import { OrderAprroval } from "../modules/allUserOrdersApproval";
// import { AccountStatusModule } from "../modules/celeb-account-approval";

// import { Forgot } from "../modules/auth/screen/forgot.screen";
import { AdminProfile } from "../modules/admin-profiles";
import { NewAdminRegister } from "../modules/register-admin";
import { Recommendation } from "../modules/recommendations";
import { AccountActivation } from "../modules/popular";
import { Promo } from "../modules/promo";
import { Users } from "../modules/main-profile";
import { ProductView } from "../modules/product-view";
import {Register} from "../modules/auth/screen/register.screen";

// import { }
// import { Signup } from "../modules/auth/screen";

// import { ContactUs } from "../modules/contactus/screen";










function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function Router() {
  return (
    <BrowserRouter>
      <Routes>
       
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route  path="/forgot" element={<Forgot />} /> */}
        {/* <Route  path="/reset" element={<Reset />} /> */}
       {/* <Route  path="/reset-success" element={<ResetSuccess} /> */}
    

      {/* <Route  path="/admin-profiles" element={<AdminProfile />} /> */}
           {/* @ts-ignore */}
     {/* <Route  path="/subscribed-profiles" element={<SubscribedProfile />} /> */}
     {/* @ts-ignore */}
     {/* <Route  path="/users-profiles" element={<Users/>} />
        <Route  path="/admin-profiles" element={<AdminProfile/>} /> */}
{/* @ts-ignore */}
         {/* <Route  path="/home" element={<Home />} /> */}
         {/* <Route  path="/order-approval" element={<OrderAprroval} /> */}
         {/* <Route  path="/account-approval" element={<AccountStatusModule} /> */}
      
         {/* <Route  path="/register-admin" element={<NewAdminRegister} /> */}
          {/* @ts-ignore */}
         {/* <Route  path="/subcription" element={<Recommendation />} /> */}
         {/* @ts-ignore */}
         {/* <Route  path="/activation" element={<AccountActivation />} />
         <Route  path="/promocodes" element={<Promo />} /> */}



{/* #############################################################################################*/}

        {/* @ts-ignore */}
        <Route path="/signup" element={<Signup />} />
        {/* @ts-ignore */}
        <Route path="/signin" element={<Signin />} />
        {/* @ts-ignore */}
        <Route path="/" element={<Home />} />
        {/* @ts-ignore */}
        <Route path="/home" element={<Home />} />
        {/* @ts-ignore */}
        <Route path="/product" element={<ProductView />} />
        {/* @ts-ignore */}
        <Route path="/contactus" element={<ContactUs />} />
          {/* @ts-ignore */}
          <Route path="/aboutus" element={<AboutUs />} />
           {/* @ts-ignore */}
           <Route path="/services" element={<Services />} />
           {/* @ts-ignore */}
           <Route path="/register" element={<Register />} />


{/* #############################################################################################*/}

       
     
      </Routes>
    </BrowserRouter>
    // <BrowserRouter>
    //   <ScrollToTop />
    //   <Switch>
        
    //     <Route  path="/forgot" element={<Forgot} />
    //     <Route  path="/reset" element={<Reset} />
    //     {/* <Route  path="/reset-success" element={<ResetSuccess} /> */}
    //     {/* <Route  path="/signup" element={<Signup} /> */}
    //     <Route  path="/signin" element={<Signin} />
    //     <Route  path="/users-profiles" element={<Users} />
    //     <Route  path="/admin-profiles" element={<AdminProfile} />
    //     <Route  path="/subscribed-profiles" element={<SubscribedProfile} />
    //     <Route  path="/home" element={<Home} />
    //     <Route  path="/" element={<Signin} />
    //     {/* <Route  path="/order-approval" element={<OrderAprroval} /> */}
    //     {/* <Route  path="/account-approval" element={<AccountStatusModule} /> */}
    //     <Route  path="/edit-profile/:id" element={<EditActorProfile} />
    //     {/* <Route  path="/register-admin" element={<NewAdminRegister} /> */}
    //     <Route  path="/subcription" element={<Recommendation} />
    //     <Route  path="/activation" element={<AccountActivation} />
    //     <Route  path="/promocodes" element={<Promo} />
        
        


        
    //   </Switch>
    // </BrowserRouter>
  );
}

export default Router;
