import React, { PureComponent } from "react";
import { connect } from "react-redux";
import AppLayout from "../../../applayout/otherlayout";
import { withTranslation } from "react-i18next";
import { sendMessage } from '../'
import "react-toastify/dist/ReactToastify.css";
import Utils from "../../../utils";
interface Props {
  t: any;
  sendMessage: Function;
  loginResponce?: any;

}
interface State {
  email: string;
  showLoginLoading: boolean;
  emailColor: string;
  textArea: string;
  emailResponse: string;
  isWhitePaperRequest:boolean;
  error: {
    emailError: string;
  };
}

export class AboutUsScreen extends PureComponent<Props, State> {


  constructor(props: Props) {
    super(props);
    this.state = {
      email: "",
      emailResponse: "",
      emailColor: "#FFFFFF",
      textArea: "",
      isWhitePaperRequest:false,
      showLoginLoading: false,
      error: {
        emailError: "",
      },
    };
  }

  componentDidMount() {

    console.log(window.location.search);
      
    if(window.location.search && window.location.search.toLocaleLowerCase().includes("iswhitepaper"))
    {
      this.setState({isWhitePaperRequest:true});
    }
  }
  handleFiledUpdate = (key: string, value: any) => {
    //@ts-ignore
    this.setState({ [key]: value });
  };
  handleKeyDown = (event: any) => {
    if (event.key === "Enter") {
      this.handleSubmit();
    }
  };

  handleSubmit = async () => 
  {
    console.log('handled')

    let { email, textArea, error } = this.state;

    let formError = Utils.constant.validateForm({ email, textArea, error });

    let isValid = Object.keys(formError).every((k) => formError[k] === "");

    this.setState({ error: Object.assign({}, formError) }, () => 
    {
      if (this.state.error.emailError != "") {
        console.log(this.state.error)
        this.setState({ emailColor: "#d65454" })
      }
      else {
        console.log(this.state.error)
        this.setState({ emailColor: "#121212" })
      }

    });
    if (!isValid) {
      return false;
    } else {

      this.setState({ showLoginLoading: true });

      const token = await localStorage.getItem("fcmToken");

      let payload =  this.state.isWhitePaperRequest? {email,textArea,isWhitePaperRequest:"true" }:{email,textArea };

      this.props.sendMessage(payload, (response: any) => 
      {
        this.setState(
          {
            email: "",
            textArea: ""
          },
          () => {
            this.setState({ emailResponse: "Sent" })

            let element = document.getElementById("body-message");

            if (element) 
            {
              element.classList.add("active");

              let errorElement = document.getElementById("api-error-message");
              
              if (errorElement) {
                element.style.backgroundColor = "#00baee";
                element.style.borderColor = "#00baee";
                errorElement.innerHTML = "Sent";
              }
              setTimeout(function () {
                if (element) {
                  element.classList.remove("active");
                }
                if (errorElement) {
                  errorElement.innerHTML = "";
                }
              }, 3000);
            }

          }
        );
      });
    }
  };
  
  // reset=( )=>{

  //   this.setState({
          
  //       email: "",
  //       emailResponse: "",
  //       emailColor: "#FFFFFF",
  //       textArea: "",
  //       isWhitePaperRequest:false,
  //       showLoginLoading: false,
  //       error: {
  //       emailError: "",
  //      },
  //   })
    
      // };

  render() {

    return (
      <>
            {/* <!--=================================
    banner --> */}
    <section className="inner-banner bg-light">
      <div className="container">
        <div className="row align-items-center intro-title">
          <div className="col-12">
            <ol className="breadcrumb mb-0">
              <li className="breadcrumb-item"><a href="/"> <i className="fas fa-home"></i> </a></li>
              {/* <li className="breadcrumb-item"> <i className="fas fa-chevron-right"></i> <a href="javascript:void(0)">Pages</a></li> */}
              <li className="breadcrumb-item active"> <i className="fas fa-chevron-right"></i> <span> About us </span></li>
            </ol>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    banner --> */}

    {/* <!--=================================
    About --> */}
    <section className="space-ptb  half-overlay right-position position-relative">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-lg-6">
            <div className="section-title left-divider">
              <span>About our company</span>
              <h2>We Focus on Your Collections, So You Can Stay Focused on Patients</h2>
              <p className="mb-0"><b>Meaningful partnerships with our clients by offering Medical Billing services designed to improve your Revenue Cycle Management RESULTS</b> <br/>
We are an Experienced Revenue Cycle Management team providing Peace of Mind and Confidence to our clients. Outsourcing your financial reimbursements to our team of CERTIFIED AAPC management, allows you to focus on your Practice and Patients. Investments are made with the returns or RESULTS in mind. Allow us to bring you the results you and your office works for day in and day out. Medical Billing Service done right. Your billing should relieve your office stress and increase your revenue at the same time.</p>
            </div>
            <p><b>Your Medical Billing Agency should be AAPC CERTIFIED team you can rely on to deliver in Confidence.</b> <br/> Our main objective is to provide services that brings your investment in us a return worth your time.

A National Article on "MEDPAGE TODAY" recently recorded practices in the US are only receiving on average 70% of Account Receivables from their in or out of house medical billing services. When you have a certified agency with expertise handling your billing, you can rest assure your investment in our team will increase your revenue while acquiring our services from 10% to 20%.

 INCREASE your BOTTOM LINE. 

Your Practice deserves to see a return in its day to day operations</p>
            {/* <div className="row my-4 mb-lg-5">
              <div className="col-md-6">
                <ul className="list-unstyled">
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Get the oars in the water and start rowing</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Execution is the single biggest factor in achievement</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Most people believe that success is difficult.</li>
                </ul>
              </div>
              <div className="col-md-6">
                <ul className="list-unstyled">
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Success is something of which we all want more.</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>The best way is to develop and follow a plan.</li>
                  <li className="mb-3 d-flex"><i className="far fa-plus-square pe-2 text-primary mt-1"></i>Introspection is the trick.</li>
                </ul>
              </div>
            </div> */}
            <div className="d-sm-flex align-items-center">
              <img className="img-fluid me-3" src="images/sign.png" alt=""/>
              <div className="author-text ps-3">
                <h5 className="text-primary">Michael Jordan</h5>
                <p className="mb-0 text-secondary">CEO & Founder</p>
              </div>
            </div>
          </div>
          <div className="col-lg-6 pl-lg-6 mt-5 mt-lg-0">
            <div className="position-relative">
              <div className="position-absolute top-0 right-0 right-dot-shape">
                <img className="img-fluid fa-flip-horizontal" src="images/svg/06.svg" alt=""/>
              </div>
              <img className="img-fluid" src="images/about/01.jpg" alt=""/>
              <div className="position-absolute bottom-0 left-0 mb-n5 ms-n5 z-index-n1">
                <img className="img-fluid" src="images/svg/08.svg" alt=""/>
              </div>
              <div className="position-absolute bottom-0 left-0 m-3">
                <img className="img-fluid" src="images/svg/07.svg" alt=""/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    About -->

    <!--=================================
    Counter --> */}
    <section className="space-ptb">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 pe-xl-4">
            <div className="section-title left-divider">
              <span>Enhancing life. excelling in care.</span>
              <h2>We’re Committed To Delivering Outstanding Services.</h2>
              <p className="mb-0">One of the main areas that I work on with my clients is shedding these non-supportive beliefs and replacing them with beliefs that will help them to accomplish their desires.</p>
            </div>
            <div className="position-relative bg-overlay-black-50">
              <img className="w-100" src="images/about/02.jpg" alt=""/>
              <div className="popup-video">
                <a className="video-btn popup-icon popup-youtube" href="https://www.youtube.com/watch?v=aBoaCHKJKd8"><i className="fas fa-play fa-1x"></i></a>
              </div>
            </div>
          </div>
          <div className="col-lg-6 mt-lg-0 mt-5">
            <div className="row">
              <div className="col-md-6">
                <div className="counter">
                  <div className="counter-icon">
                    <i className="flaticon-hospital-bed"></i>
                  </div>
                  <div className="counter-content">
                    <span className="timer" data-to="1790" data-speed="10000">1790</span>
                    <label>This is perhaps the single biggest obstacle that all of us must overcome.</label>
                    <img className="img-fluid plus-svg" src="images/svg/09.svg" alt=""/>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="counter">
                  <div className="counter-icon">
                    <i className="flaticon-electrocardiogram"></i>
                  </div>
                  <div className="counter-content">
                    <span className="timer" data-to="245" data-speed="10000">245</span>
                    <label>Commitment is something that comes from understanding that everything has its price.</label>
                    <img className="img-fluid plus-svg" src="images/svg/09.svg" alt=""/>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="counter mb-md-0">
                  <div className="counter-icon">
                    <i className="flaticon-hospital"></i>
                  </div>
                  <div className="counter-content">
                    <span className="timer" data-to="491" data-speed="10000">491</span>
                    <label>Along with your plans, you should consider developing an action orientation.</label>
                    <img className="img-fluid plus-svg" src="images/svg/09.svg" alt=""/>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="counter mb-0">
                  <div className="counter-icon">
                    <i className="flaticon-electrocardiogram-1"></i>
                  </div>
                  <div className="counter-content">
                    <span className="timer" data-to="1090" data-speed="10000">1090</span>
                    <label>This requires a little self-discipline, but is a crucial component to achievement.</label>
                    <img className="img-fluid plus-svg" src="images/svg/09.svg" alt=""/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* <!--=================================
    Counter -->

    <!--=================================
    Team --> */}
    {/* <section className="overleaping-section-title bg-primary" style={{backgroundImage: `url(images/pattern.png)`}}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-md-10 text-center">
            <div className="section-title center-divider mb-5">
              <span className="text-white">Why choose people like Medleaf</span>
              <h2 className="text-white">Our Equipped Team Is Able To Support You!</h2>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="overleaping-section space-pb">
      <div className="container">
        <div className="row">
          <div className="col-md-4 mb-md-0 mb-4">
            
            <div className="team">
              <div className="team-image">
                <img className="img-fluid b-radius-bottom-none" src="images/team/01.jpg" alt=""/>
                <div className="team-social">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
              <div className="team-detail b-radius-top-none">
                <span className="team-label">Cardiologist</span>
                <h4 className="team-title"><a href="team-single.html">Dr.Felica Queen</a></h4>
                <span className="team-phone">+(704) 279-1249</span>
                <span className="team-email">letstalk@medileaf.com</span>
              </div>
              <a className="icon-btn" href="#"><i className="fas fa-plus"></i></a>
            </div>
            
          </div>
          <div className="col-md-4 mb-md-0 mb-4">
            
            <div className="team">
              <div className="team-image">
                <img className="img-fluid b-radius-bottom-none" src="images/team/02.jpg" alt=""/>
                <div className="team-social">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
              <div className="team-detail b-radius-top-none">
                <span className="team-label">Neurologist</span>
                <h4 className="team-title"><a href="team-single.html">Dr.Alice Williams</a></h4>
                <span className="team-phone">+(704) 279-1249</span>
                <span className="team-email">letstalk@medileaf.com</span>
              </div>
              <a className="icon-btn" href="#"><i className="fas fa-plus"></i></a>
            </div>
            
          </div>
          <div className="col-md-4">
            
            <div className="team">
              <div className="team-image">
                <img className="img-fluid b-radius-bottom-none" src="images/team/03.jpg" alt=""/>
                <div className="team-social">
                  <ul>
                    <li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
                    <li><a href="#"><i className="fab fa-twitter"></i></a></li>
                    <li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
                    <li><a href="#"><i className="fab fa-instagram"></i></a></li>
                  </ul>
                </div>
              </div>
              <div className="team-detail b-radius-top-none">
                <span className="team-label">Physician Assistant</span>
                <h4 className="team-title"><a href="team-single.html">Dr.Michael Bean</a></h4>
                <span className="team-phone">+(704) 279-1249</span>
                <span className="team-email">letstalk@medileaf.com</span>
              </div>
              <a className="icon-btn" href="#"><i className="fas fa-plus"></i></a>
            </div>
            
          </div>
        </div>
      </div>
    </section> */}
    {/* <!--=================================
    Team --> */}
      </>
    );
  }
}
function mapStateToProps(state: any) {
  return {
    loginResponce: state.authReducer,
  };
}
function mapDispatchToProps(dispatch: Function) {
  return {
    sendMessage: (payload: any, callback: Function) =>
      dispatch(sendMessage(payload, callback)),
  };
}

export const AboutUsModule = withTranslation()(
  connect(mapStateToProps, mapDispatchToProps)(AboutUsScreen)
);

export const AboutUs = AppLayout(AboutUsModule);
