import React, { useState, memo } from "react";
import { LogoutConfirmationModal } from "../../component/confirmation-modal/confirmationmodal";
import  Signup  from "../../modules/auth/screen/signup.screen";
import  Signin  from "../../modules/auth/screen/signin.screen"

import { doLogin } from "../../modules/auth/auth.action";
import {  doSignup } from "../../modules/auth/auth.action";
// import { withTranslation } from "react-i18next";
// import "react-toastify/dist/ReactToastify.css";
// import Utils from "../../utils";


interface Props {
    changeLanguage?: any;
    
}
export default memo(function Onboardingheader(props: Props) {

    const [logoutConfirmation, setLogoutConfirmation] = useState(false),
    [urole, setURole]=useState(""),
            //{ t, i18n } = useTranslation(),
        userName = localStorage.getItem("username"),
        recruiterPic = localStorage.getItem("recruiterPic"),
        userid = localStorage.getItem("userId");
       var role = localStorage.getItem("role");
    localStorage.setItem("languageSelected", 'en');


    async function handleUserState(role:string) {
        console.log(' state handled')
        window.localStorage.setItem("role",role)
        // window.location.href="/signup"
        setURole(role)
    
      };


    
    
    
    
    function handleLogout() { localStorage.clear(); window.location.href = '/home' }
    function checkFunctionality() {
        var protocol = 'https';
        var userid = localStorage.getItem("userId");
         

        var page = window.location;
        if (page.href.includes('https')) { protocol = 'https' }
        else if (page.href.includes('http')) { protocol = 'http'; }
        console.log(page)
      
    }
    // function signin(payload:any){
    //     console.log('in signin')
    //     doLogin(payload, (response: any) => {
    //         if (!response.loginerror && response.user_id) {
    //         //   this.setState(
    //         //     { email: "", password: "", showLoginLoading: false },
    //         //     () => {
    //               console.log(response)
    //               localStorage.setItem("email",payload.email)
    //             // this.props.history.push('/home')
    //             window.localStorage.setItem('user_id',response.user_id)
    //             window.location.href="/home"
    //             //  this.connect();
                 
    //         // window.location.reload();
    //         //     }
    //         //   );
    //         } else {console.log(response)
    //         //   this.setState({
    //         //     callResponseError:'Invalid Username Or Password'
    //         //   })
    //           //@ts-ignore
    //         document.getElementById('errorMessage').style.display='block'
    //         setInterval(function(){ 
    //           //@ts-ignore
    //           document.getElementById('errorMessage').style.display='none' }, 5000);
    //         //   this.setState({
    //         //     showLoginLoading: false,
    //         //   });
    //         }
    //       });
    // }
    return (
       <>
        <header className="header header-03 header-transparent header-sticky">
      <div className="main-header">
        <div className="container-fluid">
          <nav className="navbar navbar-static-top navbar-expand-lg">
            <a className="navbar-brand me-0" href="/">
              <img className="img-fluid" src="images/logo-me.png" alt="logo"/>
            </a>
            <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"><i className="fas fa-align-left"></i></button>
            <div className="navbar-collapse collapse">
              <ul className="nav navbar-nav">
                <li className="nav-item dropdown active">
                  <a className="nav-link" href="/" role="button" aria-haspopup="true" aria-expanded="false">Home<i className="far fa-plus-square"></i></a>
                  
                </li>
                <li className="dropdown nav-item">
                  <a className="nav-link" href="/aboutus" >About Us<i className="far fa-plus-square"></i></a>
                  
                </li>
                <li className="dropdown nav-item">
                  <a href="/services" className="nav-link">Services<i className="far fa-plus-square"></i></a>
                </li>
                 <li className="dropdown nav-item">
                  <a href="#" className="nav-link" data-bs-toggle="dropdown">Blog<i className="far fa-plus-square"></i></a>
                  
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Shop <i className="far fa-plus-square"></i>
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/contactus">
                    Contact Us <i className="far fa-plus-square"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="add-listing d-none d-sm-block">
              <a className="me-4 text-white" href="tel:1-800-555-1234"><i className="fa fa-phone me-2 fa fa-flip-horizontal"></i>051-2318538</a>
              <a className="btn btn-white" href="#"><i className="fa fa-address-book"></i>Get a quote</a>
            </div>
          </nav>
        </div>
      </div>
    </header>
       </>
    );
});
