import React from "react";
import LayoutWrapper from "../modules/layoutwrapper/layoutwrapper";
//import "../assets/sass/authlayout.scss";
//import "../assets/css/style.css";
import Header from '../component/homeheader/homeheader'
import Footer from '../component/footer/footer';

interface Props {
  history: any;
  location: any;
  match: any;
  doLogout: Function;
}

function higherOrderComponent(WrappedComponent: any) {
  class HOCC extends React.Component<Props, any> {
    componentDidMount() { }

    render() {
      return (
        <>
         {/* <Header></Header>
          <WrappedComponent
            history={this.props.history}
            match={this.props.match}
            location={this.props.location}
          />
           <Footer></Footer>0 */}
             <LayoutWrapper
          history={this.props.history}
          doLogout={this.props.doLogout}
        >
          <WrappedComponent
            history={this.props.history}
            match={this.props.match}
            location={this.props.location}
          />
        </LayoutWrapper>
        </>
       
        
      );
    }
  }

  return HOCC;
}
export default higherOrderComponent;
