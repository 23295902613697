// import { GetAllOrders } from "../modules/actorside-order";

export default {
  updatelanguage: "user/updateLanguage",
  socialmediaLogin: "users/facebook-login",
  login: "users/login",
  // adminLogin: "login",
  unsubscribe: "users/unsubscribe",
  // signup: "users/signup",
  adminSignup: "users/adminSignup",
  forgot: "users/forgot",
  verifyOtp: "users/verifyOtp",
  sendOTP: "user/sendOtp",
  createProfile: "users/createProfile",
  resendOtp: "users/resendOtp",
  resetPassword: "users/resetPassword",
  recruiterProfile: "users/createProfile",
  jobLists: "job/list?",
  jobDetails: "job/view-detail?jobId=",
  candidateStatus: "job/detail?noticePeriod=0&jobId=",
  candidateProfile: "job/user-detail?userId=",
  recruiterProfileDetails:
    "recruiter-view?page=1&latitude&longitude&recruiterId=",
  dashboardRecruiter: "recruiter-profile",
  updateRecruiterProfile: "recruiter-update-profile",
  candidate: "job/apply-users?",
  shortListing: "job/shortlist-user",
  rejectCandidate: "job/change-status",
  SelectCategory: "category-list",
  CreateNFT: "create-nft",
  UpdateNFT: "update-nft",
  addJob: "job/add",
  updateJob: "job/edit",
  deductStars: "deduct-stars",
  staralreadyspent: "active-jobs?candidateId=",
  chnagePassword: "users/change-password",
  review: "rate-app",
  referral: "referral/counts",
  deleteAccount: "deactivate-account",
  starPackage: "plan-list?type=",
  InitializebrainTreeUrl: "client-token?userId=",
  BrainTreeSubscribe: "buy-braintree-subscription",
  BrainTreePremium: "create-transaction",
  languagesList: "language/list",
  skillsList: "skills/list",
  deleteJob: "job/delete",
  reopenjob: "job/reopen",
  getCardsList: "card-list",
  addCards: "add-cards",
  purchasedPackages: "transaction-list?page=",
  contactUss: "contactus",
  sendRoomNotification: "notify-call",
  savetemplate: "message/template",
  getstories: "user/story/list",
  createstory: "create/story",
  removestory: "story/delete?storyId=",
  sendquery: "contactus",
  makestarpayment: "makepayment",
  makestripepayment: "makepayment",
  makepaypalpayment: "makepaypalpayment",
  makealipaypayment: "makealipaypayment",
  executepayment: "executepayment",
  getpaymentdetails: "subscriptionDetails?orderId=",
  cancelSubscription: "cancelSubscription",
  getsubscriptiondetails: "subscriptionDetailsByUserId",
  updatetour: "updateTourEnable",
  getstatus: "users/get-status",
  discounts: 'users/discount',
  transection: 'users/transection',
  // contactus: "users/contactus-message",
  updateProfile:"users/update-profile",
  getUserProfileData: "users/getUserProfileData",
  getUserProfileDataForCustomer: "users/getUserProfileDataForCustomer",
  // getUserProfileDataForAdmin: "users/getUserProfileDataForAdmin",
  // placeOrder: "orders/placeOrder",
  // updateOrderTransaction: "orders/updateOrderTransaction",
  getAllActors:"users/getAllActors",
  // getAllAccounts:"users/getAllAccounts",
  // getAllUsers:"users/getAllUsers",
  getAllAdmins:"users/getAllAdmins",
  getAllOrders:"users/getAllOrders",
  getAllOrdersForAdmin:"users/getAllOrdersForAdmin",
  // getAllUserOrders:"users/getAllUserOrders",
  // updateVerificationStatus:"users/updateVerificationStatus",
  updateOrderStatus:"users/updateOrderStatus",
  updateRecomendationStatus:"users/updateRecommendationStatus",
  updatePopularStatus:"users/updatePopularStatus",
  deleteProfile:"users/deleteProfile",
  accountVerifyEmail: "users/accountVerifyEmail",
  count: "users/count",
  // deliverOrders:"users/deliverOrder",
  // downloadVideo:"orders/downloadVideo"
  updateSubscriptionStatus:"users/updateSubscriptionStatus",
  getAllSubscribedUsers:"users/getAllSubscribedUsers",
  // updateActivationStatus:"users/updateActivationStatus",
  getPromo:"users/getPromo",
  updateExpiryDate:"users/updateExpiryDate",
  updateBooksCount:"users/updateBooksCount",
  sendPromoEmailUnSub:"users/sendPromoEmailUnSub",





////////////////////////////////////
adminLogin: "login",
getAllUsers:"getAllUsers",
updateActivationStatus:"activateUsers",
updateVerificationStatus:"updateVerificationStatus",
getAllAccounts:"getAccountApprovalRequests",
signup: "register",
register: "register",



/////////////////////
 contactus: "contactus",

};
